import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import RankedMiniGameCard from './RankedMiniGameCard'
import RankedBigGameCard from './RankedBigGameCard'
import LoadingBigGameCard from './LoadingBigGameCard'
import LoadingMiniGameCard from './LoadingMiniGameCard'
import { useTranslation } from 'react-i18next'

function RankedGamePanel() {
    const [randomAdminGames, setRandomAdminGames] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const { t } = useTranslation();



    const getRandomAdminGames = async () => {
        await axios.get('/game/adminRandomGames')
            .then((response) => {
                setRandomAdminGames(response.data)
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }

    useEffect(() => {
        getRandomAdminGames()
    }, [])

    return (
        <div className=' font-bodyFont '>
            <div className='flex justify-between mt-14'>
                <h1 className="lg:text-3xl sm:text-xl font-bold  text-white ">
                    {t('mainPage.gamePanel.title1')}
                </h1>
                <Link
                    onClick={() => {
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        });
                    }}
                    to={"/rankedGames"} >

                    <h1 className="lg:text-xl sm:text-base font-bold  text-red-500  hover:animate-pulse  hover:scale-105">
                        {t('mainPage.gamePanel.text1')}
                    </h1>
                </Link>
            </div>
            <div className='mx-1'>
                {(randomAdminGames.length == 0 && !isLoaded) && (
                    <>
                        <LoadingBigGameCard></LoadingBigGameCard>
                        <div className='mt-6  grid lg:grid-cols-2  sm:grid-cols-1 gap-4'>
                            <LoadingMiniGameCard></LoadingMiniGameCard>
                            <LoadingMiniGameCard></LoadingMiniGameCard>
                        </div>
                    </>
                )}

                {randomAdminGames.length > 0 && (
                    <Link to={"/gameView/" + randomAdminGames[0].id}>
                        <RankedBigGameCard id={randomAdminGames[0].id} gameName={randomAdminGames[0].gameName} gameCreatorUserId={randomAdminGames[0].gameCreatorUserId} gameCreatorUserName={randomAdminGames[0].gameCreatorUserName} gameTypeName={randomAdminGames[0].gameTypeName} gamePreviewImage={randomAdminGames[0].gamePreviewImage} playerCount={randomAdminGames[0].playerCount} gameCreatorUserAvatarName={randomAdminGames[0].gameCreatorUserAvatarName} admin={randomAdminGames[0].admin}></RankedBigGameCard>
                    </Link>
                )}

                <div className=' mt-6  grid lg:grid-cols-2  sm:grid-cols-1 gap-4'>
                    {randomAdminGames.slice(1, 3).map((key, index) => (
                        <Link to={"/gameView/" + key.id} key={index}>
                            <RankedMiniGameCard id={key.id} gameName={key.gameName} gameCreatorUserId={key.gameCreatorUserId} gameCreatorUserName={key.gameCreatorUserName} gameTypeName={key.gameTypeName} gamePreviewImage={key.gamePreviewImage} playerCount={key.playerCount} gameCreatorUserAvatarName={key.gameCreatorUserAvatarName} admin={key.admin}></RankedMiniGameCard>
                        </Link>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default RankedGamePanel