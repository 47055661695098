import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function SessionStatus(props) {
    const { session } = props
    const [showModal, setShowModal] = React.useState(true)
    const { t } = useTranslation();
    let navigate = useNavigate();

    const closeQuitlert = () => {

        localStorage.removeItem("signedRefreshToken");
        localStorage.removeItem("signedUserAvatarName");
        localStorage.removeItem("signedUserId");

        setShowModal(false);
        navigate("/login");

    };


    return (
        <div>
            {showModal ? (
                <div className="fixed inset-0 flex items-center justify-center z-50 px-4  backdrop-blur-sm">
                    <div className="fixed inset-0 bg-black opacity-40 z-40"></div>
                    <div
                        className="rounded border-s-4 border-red-500 bg-zinc-900 p-4 relative z-50 max-w-xl mx-auto w-full"
                    >
                        <strong className="block text-6xl mb-5 font-medium text-white">
                            :(
                        </strong>
                        <p className="mt-2 text-xl text-white">
                            {session}
                        </p>
                        <div className='mt-2 flex justify-end'>
                            <button onClick={closeQuitlert} className="hover:bg-red-600 duration-200  text-xl   bg-red-500 px-3 py-2 rounded-lg text-white">{t("game.buttonText6")} </button>
                        </div>
                    </div>
                </div>
            ) : null}

        </div>
    )
}

export default SessionStatus