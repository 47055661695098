import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { FaPhoneFlip, FaHandshakeSimple } from 'react-icons/fa6'

function Contact() {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = "Guess The Game - Game Guess - Contact";
        return () => {
            document.title = "Guess The Game - Game Guess ";
        };
    }, []);
    return (

        <section className=" font-bodyFont text-white mt-10">
            <p className='lg:text-4xl md:text-3xl sm:text-xl  text-xl text-red-500  font-bodyFont text-center  cursor-default  my-7'>
                {t('contact.title1')}
            </p>
            <div className="container mx-auto flex flex-wrap justify-center">

                <div className="lg:w-1/3 sm:w-full p-4">
                    <div className="h-full bg-zinc-800 bg-opacity-75 lg:px-8 lg:pt-8 sm:px-4 sm:pt-4 rounded-lg overflow-hidden text-center relative">
                        <FaPhoneFlip className='flex lg:w-16 lg:h-16 sm:w-8 sm:h-8 w-8 h-8 mt-1 text-red-500 '></FaPhoneFlip>
                        <h2 className="tracking-widest lg:text-xl sm:text-sm text-base title-font font-medium my-5">{t('contact.text1')}</h2>
                        <p className="leading-relaxed mb-3 lg:text-lg sm:text-sm text-sm">{t('contact.text2')}</p>
                        <a href="mailto:gagusupteam@gmail.com" className="text-black lg:text-lg sm:text-sm text-sm font-bold rounded-lg inline-flex items-center px-5 py-2 mb-4 bg-indigo-500 hover:bg-indigo-400 duration-200 cursor-pointer">
                            gagusupteam@gmail.com
                        </a>
                    </div>
                </div>
                <div className="lg:w-1/3 sm:w-full p-4">
                    <div className="h-full bg-zinc-800 bg-opacity-75 lg:px-8 lg:pt-8 sm:px-4 sm:pt-4 rounded-lg overflow-hidden text-center relative">
                        <FaHandshakeSimple className='flex lg:w-16 lg:h-16 sm:w-8 sm:h-8 w-8 h-8 mt-1 text-red-500 '></FaHandshakeSimple>
                        <h2 className="tracking-widest lg:text-xl sm:text-sm text-base title-font font-medium my-5">{t('contact.text3')}</h2>
                        <p className="leading-relaxed mb-3 lg:text-lg sm:text-sm text-sm">{t('contact.text4')}</p>
                        <a href="mailto:gagu@gameguesser.xyz" className="text-black lg:text-lg sm:text-sm text-sm font-bold rounded-lg inline-flex items-center px-5 py-2 mb-4 bg-indigo-500 hover:bg-indigo-400 duration-200 cursor-pointer">
                            gagu@gameguesser.xyz
                        </a>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default Contact