import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import NormalBigGameCard from './NormalBigGameCard'
import NormalMiniGameCard from './NormalMiniGameCard'
import LoadingBigGameCard from './LoadingBigGameCard'
import LoadingMiniGameCard from './LoadingMiniGameCard'
import { useTranslation } from 'react-i18next'


function NormalGamePanel(props) {
    const [randomGames, setRandomGames] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const { id, gameTypeName } = props;
    const { t } = useTranslation();

    const getRandomGames = async () => {
        await axios.get('/game/randomGames/' + id)
            .then((response) => {
                setRandomGames(response.data)
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }

    useEffect(() => {
        getRandomGames()
    }, [])

    return (
        <div className='font-bodyFont'>
            <div className='flex justify-between mt-20'>
                <h1 className="lg:text-3xl sm:text-xl font-bold  text-white ">
                    {t('mainPage.gamePanel.' + gameTypeName)}

                </h1>
                <Link
                    onClick={() => {
                        window.scroll(0.0, {
                            behavior: "smooth",
                        });

                    }}
                    to={"/gameCategory/" + id}>
                    <h1 className="lg:text-xl sm:text-base font-bold  text-red-500  hover:animate-pulse  hover:scale-105">
                        {t('mainPage.gamePanel.text1')}
                    </h1>
                </Link>
            </div>
            <div className='mx-1'>
                {(randomGames.length == 0 && !isLoaded) && (
                    <>
                        <LoadingBigGameCard></LoadingBigGameCard>
                        <div className='mt-6 grid lg:grid-cols-3  sm:grid-cols-1 gap-4'>
                            <LoadingMiniGameCard></LoadingMiniGameCard>
                            <LoadingMiniGameCard></LoadingMiniGameCard>
                            <LoadingMiniGameCard></LoadingMiniGameCard>
                        </div>
                    </>
                )}

                {randomGames.length > 0 && (
                    <Link to={"/gameView/" + randomGames[0].id}>
                        <NormalBigGameCard id={randomGames[0].id} gameName={randomGames[0].gameName} gameCreatorUserId={randomGames[0].gameCreatorUserId} gameCreatorUserName={randomGames[0].gameCreatorUserName} gameTypeName={randomGames[0].gameTypeName} gamePreviewImage={randomGames[0].gamePreviewImage} playerCount={randomGames[0].playerCount} gameCreatorUserAvatarName={randomGames[0].gameCreatorUserAvatarName} isVerified={randomGames[0].verified}></NormalBigGameCard>
                    </Link>
                )}

                <div className=' mt-6 grid lg:grid-cols-3  sm:grid-cols-1 gap-4 '>
                    {randomGames.slice(1, 4).map((key, index) => (
                        <Link to={"/gameView/" + key.id} key={index}>
                            <NormalMiniGameCard id={key.id} gameName={key.gameName} gameCreatorUserId={key.gameCreatorUserId} gameCreatorUserName={key.gameCreatorUserName} gameTypeName={key.gameTypeName} gamePreviewImage={key.gamePreviewImage} playerCount={key.playerCount} gameCreatorUserAvatarName={key.gameCreatorUserAvatarName} isVerified={key.verified}></NormalMiniGameCard>
                        </Link>
                    ))}
                </div>

            </div>



        </div>
    )
}

export default NormalGamePanel