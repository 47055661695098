import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FiClipboard, FiInfo } from 'react-icons/fi';
import { GiCrossedSwords } from 'react-icons/gi';
import GuestView from './GuestView';
import axios from 'axios';
import { formatDate } from '../../../service/FormatDate';
import SessionStatus from '../../../service/SessionStatus';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { useToken } from '../../../service/context/TokenProvider';
import { getAvatarImageFromLocalStorage } from '../../../service/Avatar';
import { Helmet } from 'react-helmet';

function GameView(props) {
    const [showNotification, setShowNotification] = useState(false);
    const { gameId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [game, setGame] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [session, setSession] = useState(false)
    const [leaderboard, setLeaderboard] = useState([])
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const clipboardRef = useRef(null);

    const { tokenData } = useToken();
    const { subId, accessToken } = tokenData;

    const getGameById = async () => {
        await axios.get('/game/withImage/' + gameId + (subId ? '?userId=' + subId : (localStorage.getItem('guestId') ? '?guestId=' + localStorage.getItem('guestId') : '')))
            .then((response) => {
                setGame(response.data)
                console.log(response.data)
                setIsLoaded(true);
                if (response.data.admin == true) {
                    setIsAdmin(true)
                }
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }

    const fillLeaderboard = () => {
        axios.get('/played/game/' + gameId)
            .then((response) => {
                setLeaderboard(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const startGame = (e) => {
        e.preventDefault();
        axios.post('/played/check', {
            userId: subId,
            gameId: gameId
        }, {
            headers: {
                Authorization: accessToken
            }
        })
            .then(response => {

                if (localStorage.getItem('guestId')) {
                    axios.post('/guestplayed/check?gameId=' + gameId + '&guestId=' + localStorage.getItem('guestId'), {}, {
                        headers: {
                            Authorization: accessToken
                        }
                    })
                        .then((guestPlayedResponse) => {
                            startGameSession(response.data.gamePlayed, guestPlayedResponse.data)
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                } else {
                    startGameSession(response.data.gamePlayed, false)
                }

            })
            .catch(error => {
                if (error.response.data.message === "Access Denied") {
                    setIsLoaded(true);
                    setSession("Session has expired, please log in again.");
                    return
                }
                console.error(error)
                setError("Please try again later or contact support.")
            })
    }

    const startGameSession = (userGamePlayed, guestGamePlayed) => {
        axios.post('/section/startgame', {
            gameId: gameId,
            userId: subId,
            transferPlay: guestGamePlayed == true ? true : false,
            token: accessToken
        }, {
            headers: {
                Authorization: accessToken
            }
        })
            .then(startResponse => {
                if (userGamePlayed == true || guestGamePlayed == true) {
                    navigate('/game/' + gameId, { state: { "isGamePlayedState": true, "navigated": true } });
                } else {
                    navigate('/game/' + gameId, { state: { "isGamePlayedState": false, "navigated": true } });
                }
            })
    }

    const handleClipboardClick = () => {
        const textToCopy = window.location.href;

        if (textToCopy) {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            document.body.appendChild(textArea);
            textArea.select();

            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 2000);
                }
            } catch (err) {
                console.error('Kopyalama işlemi sırasında bir hata oluştu:', err);
            }

            document.body.removeChild(textArea);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Sayfanın en üstüne scroll yap

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        getGameById()
        fillLeaderboard()
    }, [])
  

    if (session) {
        return (
            <SessionStatus session={session}></SessionStatus>
        )
    } else if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen flex-col">
                <div className="text-4xl font-bold text-red-500 mb-4">An unexpected error occurred...</div>
                <div className="text-2xl font-bold text-red-500">Please try again later.</div>
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div class='flex justify-center items-center text-white border-red-600 rounded-lg'>
                <div class="border-t-transparent mt-20 border-solid animate-spin rounded-full border-red-500 border-8 h-28 w-28">
                </div>
            </div>
        )
    } else
        return (
            <section
                class="text-gray-600 font-bodyFont overflow-hidden  h-screen" >
                <Helmet>
                    <title>Guess The Game - Game Guess - Game View</title>
                    <meta name="description" content=" Guess the Game - Game Guess. Enjoy the game guess gaming experience, and compete to be on the top player list! Guess the game from the pictures! The best game characters guess and game picture guess games are here!" />
                </Helmet>
                {showNotification && (
                    <div role="alert" class=" absolute  m-2">
                        <div className="rounded-xl border  bg-green-400 p-4">
                            <div className="flex items-start gap-4">
                                <span >
                                    <FiInfo className="text-white w-10 h-10" />
                                </span>
                                <div className="flex-1 text-white">
                                    <strong className="block font-medium">Notification!</strong>
                                    <p className="mt-1 text-sm">Link copied successfully.</p>
                                </div>

                                <button
                                    className="text-gray-500 transition hover:text-gray-600"
                                    onClick={() => setShowNotification(false)}
                                >
                                    <span className="sr-only">Dismiss popup</span>

                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div class="container  lg:px-10 px-5 max-w-6xl  mt-10 mx-auto">
                    <div class=" mx-auto flex flex-wrap">
                        <img alt="Guess The Game Image"
                            class="lg:w-1/2 w-full h-full object-contain object-center rounded-lg ring-1 ring-zinc-500  " src={`data:image/jpeg;base64,${game.gamePreviewImage}`} />
                        <div class="lg:w-1/2 w-full lg:pl-10">
                            <h1 class="text-white flex items-center lg:text-3xl md:text-xl sm:text-xl text-2xl title-font font-medium mb-1 lg:mt-0 mt-2">
                                {game.gameName}
                                {game.admin === true ? <BsFillPatchCheckFill className="inline-block lg:text-2xl  sm:text-xl text-xl  text-green-500 ml-2" /> : ""}
                            </h1>
                            <h3 class="text-gray-600 lg:text-base text-sm  mb-1">{t("gameView.text13")} {game.playerCount} </h3>

                            <div class="flex text-white  lg:text-lg text-sm">
                                {t("gameView.text5")}<Link to={isAdmin ? "/rankedGames" : "/userProfile/" + game.gameCreatorUserId} target='_blank'><span class="ml-2 hover:text-red-500 duration-200">{game.gameCreatorUserName} </span></Link>
                            </div>
                            <div class="flex text-white  lg:text-lg text-sm">
                                {t("gameView.text4")}<Link target='_blank' to={"/gameCategory/" + game.gameType.id}><span class="ml-2 hover:text-red-500 duration-200">{t('mainPage.gamePanel.' + game.gameType.gameTypeName)}</span></Link>
                            </div>
                            <div class="flex text-white lg:text-lg text-sm">
                                {t("gameView.text12")}<span class="ml-2">{game.squareAmount} </span>
                            </div>
                            <div class="flex text-white lg:text-lg text-sm">
                                {t("gameView.text11")}<span class={game.squareAmount === 1 ? "ml-2 text-green-500" :
                                    game.squareAmount === 4 ? "ml-2 text-yellow-500" :
                                        game.squareAmount === 9 ? "ml-2 text-orange-500" : "ml-2 text-red-500"}>
                                    {game.squareAmount === 1 ? t("gameView.text7") : game.squareAmount === 4 ? t("gameView.text8") : game.squareAmount === 9 ? t("gameView.text9") : t("gameView.text10")}</span>
                            </div>

                            <div className="flex mt-6 items-center text-white pb-5 border-b font-medium border-gray-500 mb-5 relative">
                                <span
                                    ref={clipboardRef}
                                    onClick={handleClipboardClick}
                                >
                                    <FiClipboard className="text-green-500 w-6 h-6 hover:text-green-400 hover:-translate-y-1 cursor-pointer duration-200"
                                    />
                                </span>
                                {/* <GiCrossedSwords className='ml-4 text-gray-500 w-6 h-6 hover:text-yellow-400 hover:-translate-y-1 cursor-pointer duration-200'></GiCrossedSwords> */}

                                <Link to="/howtoplay" target='_blank'>
                                    <FiInfo className='ml-4 text-blue-500 w-6 h-6 hover:text-blue-400 hover:-translate-y-1 cursor-pointer duration-200' />
                                </Link>
                            </div>

                            <div class="flex">
                                {subId === null
                                    ?
                                    <GuestView gameId={gameId} isGamePlayed={game.isGamePlayed} />
                                    :
                                    <button
                                        class="group relative w-full inline-block overflow-hidden border border-red-500 px-8 py-4 focus:outline-none rounded-lg"
                                        onClick={startGame}
                                    >
                                        <span
                                            class="absolute  inset-y-0 left-0 w-[2px] rounded-md duration-200 bg-red-500  transition-all group-hover:w-full group-active:bg-red-500"
                                        ></span>
                                        <span
                                            class="relative text-lg font-medium text-white transition-colors group-hover:text-white"
                                        >
                                            {game.isGamePlayed ? t("gameView.buttonText3") : t("gameView.buttonText2")}
                                        </span>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='lg:w-1/2 h-full text-white lg:mt-4 md:mt-4 sm:mt-4 mt-4 border-2 border-zinc-500 shadow-md bg-zinc-900 shadow-black rounded-sm'>
                        <table className=" w-full">
                            <thead className="w-full border-b font-medium border-zinc-500 text-white bg-zinc-800 ">
                                <tr className=''>
                                    <th scope="col" className="w-1/3 py-2 lg:text-xl sm:text-xs text-xs">{t("topPlayerPage.text1")}</th>
                                    <th scope="col" className="w-1/3 py-2 lg:text-xl sm:text-xs text-xs">{t("topPlayerPage.text2")}</th>
                                    <th scope="col" className="w-1/3 py-2 lg:text-xl sm:text-xs text-xs">{t("topPlayerPage.text3")}</th>
                                </tr>
                            </thead>
                            <tbody className=''>
                                {leaderboard.length !== 0 ? (
                                    leaderboard.map((item, index) => (
                                        <tr className="border-neutral-500 lg:text-xl sm:text-xs text-xs">
                                            <div className='flex justify-center'>
                                                <td className="whitespace-nowrap px-8 py-2 lg:text-xl sm:text-xs">{index + 1}</td>
                                            </div>
                                            <td className="whitespace-nowrap px-8 py-2">
                                                <div className='flex items-center'>
                                                    <img src={`data:image/jpeg;base64,${getAvatarImageFromLocalStorage(item.userAvatarName)}`} className="lg:h-8 lg:w-8 sm:w-6 sm:h-6 h-6 w-6 rounded-full" />
                                                    <Link className='hover:text-red-500 duration-200' to={"/userProfile/" + item.userId}>
                                                        <div className='ms-2 truncate'>
                                                            {item.userName}
                                                        </div>
                                                    </Link>
                                                </div>
                                            </td>
                                            <div className='flex justify-center'>
                                                <td className="whitespace-nowrap px-8 py-2">{item.score}</td>
                                            </div>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="border-neutral-500 lg:text-xl sm:text-xs text-xs">
                                        <div className='flex justify-center'>
                                            <td className="whitespace-nowrap px-8 py-2 lg:text-xl sm:text-xs"></td>
                                        </div>
                                        <td className="whitespace-nowrap px-8 py-2">
                                            <div className='flex items-center'>
                                                <div className='ms-2 truncate'>
                                                    Leaderboard not found.
                                                </div>
                                            </div>
                                        </td>
                                        <div className='flex justify-center'>
                                            <td className="whitespace-nowrap px-8 py-2"></td>
                                        </div>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

            </section >
        )
}

export default GameView