import axios from 'axios';
import React, {  useState } from 'react'
import { FaTrash} from 'react-icons/fa6';
import { useToken } from '../../service/context/TokenProvider';
import { formatDate } from '../../service/FormatDate';
import AdminViewGame from './AdminViewGame';
import AdminViewUser from './AdminViewUser';

export default function AdminViewReport(props) {
    const [showModal, setShowModal] = React.useState(false);
    const { tokenData } = useToken();
    const { accessToken } = tokenData
    const [isLoaded, setIsLoaded] = useState(false);
    const { id, children, createUserId, gameId, gameName } = props;
    const [error, setError] = useState(false);
    const [dReport, setDReport] = useState(false);

    const [report, setReport] = useState()


    const getReportById = async () => {
        await axios.get('/message/getOne/' + id, {
            headers: {
                Authorization: accessToken
            }
        })
            .then((response) => {
                setReport(response.data)
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }
    const deleteReport = async () => {
        await axios.delete(`/message/` + id, {
            headers: {
                Authorization: accessToken
            }
        })
            .catch(function (error) {
                setDReport(error)
            });
        window.location.reload();
    };


    return (
        <>
            <a
                className='w-full'
                onClick={() => {
                    setShowModal(true)
                    getReportById()
                }}
                type="button">
                {children}
            </a>

            {showModal ? (
                <>
                    <div className=" justify-center text-white  items-center font-bodyFont  overflow-x-hidden overflow-y-auto fixed  inset-0 z-50  outline-none focus:outline-none backdrop-blur-sm">
                        <div className="relative font-bodyFont w-auto my-6 mx-auto max-w-2xl">

                            {/*content*/}
                            <div className=" bg-zinc-800  border-gray-600  rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
                                {/*body*/}
                                {isLoaded ? (
                                    <div className="relative block ml-4">
                                        {/*body*/}
                                        <div className="relative">
                                            <div className="font-bodyFont">
                                                <button
                                                    className="absolute top-0 right-0 p-2 text-white hover:text-gray-400"
                                                    onClick={() => setShowModal(false)}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M6 18L18 6M6 6l12 12"
                                                        />
                                                    </svg>
                                                </button>
                                                <div className='p-4'>
                                                    <p className='text-xl'>id : <span className='text-blue-400'> {report.id}</span>  </p>
                                                    <AdminViewUser id={createUserId}>
                                                        <p className='text-xl'>CreaterUser id :<span className='text-blue-400 cursor-pointer hover:text-blue-500'> {createUserId}</span>  </p>
                                                    </AdminViewUser>
                                                    <AdminViewGame id={gameId} gameName={gameName}>
                                                        <p className='text-xl'>Game id :<span className='text-blue-400 cursor-pointer hover:text-blue-500'> {gameId} </span>  </p>
                                                    </AdminViewGame>
                                                    <p className='text-xl'>Report Type :<span className='text-blue-400'> {report.messageType.messageTypeName}</span>  </p>
                                                    <p className='text-xl'>Create Date : <span className='text-blue-400'>  {formatDate(report.createDate)}</span> </p>
                                                    <p className='text-xl mt-4'>Explanation : <span className='text-blue-400' style={{ whiteSpace: 'normal' }}> {report.messageText}</span>  </p>
                                                </div>
                                                <div className='flex my-5 justify-evenly'>
                                                    <FaTrash onClick={deleteReport} className='mt-5  hover:-translate-y-1 duration-200 cursor-pointer text-3xl mr-5 text-red-500 hover:text-red-600' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="fixed inset-0 flex items-center justify-center bg-opacity-0 backdrop-filter backdrop-blur-sm z-10">
                                        <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-4 h-40 w-40 sm:h-20 sm:w-20 mb-24"></div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>

                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );

}