import React from 'react'

function LoadingRankedGameMiniCard() {
    return (
        <div href="#" className="block  w-full p-1 hover:scale-105 duration-200 cursor-pointer ">
            <div className='h-32 w-full object-fill  sm:h-40 lg:h-40 rounded-t-md  bg-zinc-700 animate-pulse'></div>
            <div className='bg-zinc-800 rounded-b-md'>
                <div className=' flex justify-between  pt-4 px-1'>
                    <div className="py-2 mt-2 h-4 w-56 mb-2 bg-zinc-700 rounded animate-pulse justify-center"></div>
                </div>
            </div>

        </div>
    )
}

export default LoadingRankedGameMiniCard