import React from 'react'

function LoadingTrendGameCard() {
    return (
        <div href="#" className="block  hover:scale-105 duration-200 cursor-pointer">
            <div className='h-32 w-full sm:h-40 lg:h-40 rounded-t-md bg-zinc-700 animate-pulse'></div>
            <div className=' bg-zinc-800 rounded-b-md'>
                <div className="py-2 flex items-center justify-center ">
                    <div className="h-4 w-1/2 mb-2 bg-zinc-700 rounded animate-pulse justify-center"></div>
                </div>
                <div className="flex  items-center ">
                    <div className=' flex items-center my-2 mx-5'>
                        <div className='h-10 w-10 rounded-full bg-zinc-700'></div>
                    </div>
                    <div className="h-4 w-1/4 mb-2 bg-zinc-700 rounded animate-pulse justify-center"></div>
                </div>
            </div>
        </div>
    )
}

export default LoadingTrendGameCard