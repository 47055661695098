import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { HiRefresh } from 'react-icons/hi';

export default function VerifyMail(props) {
    const { setVerificationState, sendVerificationCode, user, onSubmit } = props;
    const { t } = useTranslation();

    const [seconds, setSeconds] = useState(100);
    const [verificationCode, setVerificationCode] = useState(null);
    const [isVerficationCodeWrong, setIsVerificationCodeWrong] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [seconds]);

    const percentage = ((seconds / 180) * 100) / 1.5;

    const circleStyle = {
        clipPath: `circle(${percentage}% at 50% 50%)`,
    };

    const onInputChange = (e) => {
        setVerificationCode(e.target.value)
    };

    const reSendVerificationCode = async () => {
        sendVerificationCode()
    };

    const validateFormInput = async (event) => {
        event.preventDefault();
        axios.post('/user/verification/check?userMail=' + user.userMail + '&verificationCode=' + verificationCode)
            .then(res => {
                setVerificationState(res.data)
                setTimeout(() => {
                    onSubmit()
                }, 3000);
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    setIsVerificationCodeWrong(true)
                }
            })
    };

    return (
        <div className=" justify-center text-white  items-center font-bodyFont  mt-20 overflow-x-hidden overflow-y-auto fixed inset-0 z-50  outline-none focus:outline-none backdrop-blur-sm">
            <div className="relative font-bodyFont w-auto my-6 mx-auto max-w-3xl">

                {/*content*/}
                <div className=" bg-zinc-800  border-gray-600  rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
                    {/*body*/}

                    <div className=" relative block  ml-4 ">
                        <form

                            className="">

                            <div className="flex mt-2 px-4   flex-col justify-between">

                                <label htmlFor="HeadlineAct" className="block  text-lg font-medium text-white my-2 text-left">
                                    {t("verifyMailPanel.title1")}
                                </label>


                                <div className="flex items-center">
                                    <input
                                        onChange={(e) => { onInputChange(e) }}
                                        type="text"
                                        id="messageTexts"
                                        name="messageText"
                                        maxLength={250}
                                        className="block w-full px-4 py-3 text-white  border border-gray-200 rounded-md bg-zinc-900  focus:ring-red-300 focus:ring-opacity-40 focus:border-red-300 focus:outline-none focus:ring" />

                                    {
                                        seconds === 0 ?
                                            <div className='relative ms-5 align-middle  justify-center items-center cursor-pointer'>
                                                <HiRefresh
                                                    className='w-14 h-14 items-center'
                                                    onClick={() => { reSendVerificationCode() }}>
                                                </HiRefresh>
                                            </div>
                                            :
                                            <div className="ms-5">
                                                <div className="relative w-14 h-14">
                                                    <div className="absolute top-0 left-0 w-full h-full rounded-full bg-gray-600 border border-gray-800"></div>
                                                    <div
                                                        className="absolute top-0 left-0 w-full h-full rounded-full bg-red-500"
                                                        style={circleStyle}
                                                    ></div>
                                                    <div
                                                        className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center text-white"
                                                    >
                                                        <div className="text-lg">{seconds}</div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>

                            </div>

                            {
                                isVerficationCodeWrong ?
                                    <p className="mt-3 text-red-500 px-4 font-semibold text-xl"> {t("verifyMailPanel.text1")}
                                    </p>
                                    :
                                    null
                            }

                            <div className="flex items-center justify-center mb-4 mt-4  w-full rounded-b">
                                <button
                                    className="border-white border-2 duration-300  transform inline-block rounded-md text-lg bg-zinc-800 px-4 py-2 mr-2 ml-2 hover:text-red-500 hover:border-red-500 text-white  hover:bg-zinc-900 "
                                    type="button"
                                    onClick={() => { setVerificationState(null) }}

                                >
                                    {t("verifyMailPanel.buttonText1")}
                                </button>
                                {
                                    seconds === 0 ?
                                        null
                                        :
                                        <button
                                            onClick={validateFormInput}
                                            className="border-white border-2 duration-300  transform inline-block rounded-md text-lg bg-zinc-800 px-4 py-2  mr-2 ml-2 hover:text-green-500 hover:border-green-500 text-white  hover:bg-zinc-900 "
                                            type="submit"
                                        >
                                            {t("verifyMailPanel.buttonText2")}

                                        </button>
                                }
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    )
}
