import React from 'react'
import { Link } from 'react-router-dom'
import { getAvatarImageFromLocalStorage } from '../../../service/Avatar';
import { BsFillPatchCheckFill } from 'react-icons/bs';

function NormalMiniGameCard(props) {
    const { id, gameName, gameCreatorUserId, gameCreatorUserName, gameTypeName, gamePreviewImage, playerCount, gameCreatorUserAvatarName, isVerified, admin } = props;

    return (

        <div href="#" className="block hover:scale-105 duration-200 cursor-pointer ">
            <img
                alt="Guess The Game Image"
                src={`data:image/jpeg;base64,${gamePreviewImage}`}
                className=" w-full object-fill h-32 sm:h-32 lg:h-40  rounded-t-lg"
            />
            <div className=' bg-zinc-800 rounded-b-md'>
                <div className=" text-center ">
                    <p href="#" className="block lg:text-base sm:text-sm  font-normal  text-white " >{gameName}</p>
                </div>
                <div className="flex  items-center ">
                    <div className=' flex items-center  mb-1 mx-5'>
                        <img
                            className="h-10 w-10 rounded-full  ring-2 ring-gray-400"
                            src={`data:image/jpeg;base64,${getAvatarImageFromLocalStorage(gameCreatorUserAvatarName)}`}
                            alt="Game Creator Avatar"
                        />
                        <div className='flex justify-between'>
                            <Link to={admin ? "/rankedGames" : "/userProfile/" + gameCreatorUserId}>

                                <div href="#" className="text-center lg:text-base sm:text-sm    text-white hover:underline ml-3" tabIndex="0" role="link">
                                    {gameCreatorUserName}
                                    {isVerified === true ? <BsFillPatchCheckFill className="inline-block lg:text-base sm:text-sm text-sm  text-indigo-500 ml-2" /> : ""}
                                    {admin === true ? <BsFillPatchCheckFill className="inline-block lg:text-base sm:text-sm text-sm  text-green-500 ml-2" /> : ""}
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        //         {/* <div href="#" className="flex flex-col items-center justify-center hover:bg-zinc-700 duration-200 rounded-md bg-zinc-800 w-96  cursor-pointer">

        //     <div className="border-2 border-white rounded-full w-20 h-20 flex items-center justify-center">
        //         <FaPlus className="text-xl text-white"></FaPlus>
        //     </div>
        //     <p className="mt-4 text-md  text-white">Create Game</p>
        // </div> */}


    )
}

export default NormalMiniGameCard