import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import 'swiper/css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import LoadingRankedGameMiniCard from './LoadingRankedGameMiniCard';
import RankedGameMiniCard from './RankedGameMiniCard';
import { Link } from 'react-router-dom';

function RankedGame(props) {
    const { id, gameTypeName } = props;
    const [adminGames, setAdminGames] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const { t } = useTranslation();
    const [slidesPerView, setSlidesPerView] = useState(3);



    const getAdminGames = async () => {
        await axios.get('/game/adminGames/' + id)
            .then((response) => {
                setAdminGames(response.data)
                setIsLoaded(true);

            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }

    useEffect(() => {
        getAdminGames()
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setSlidesPerView(1);
            } else {
                setSlidesPerView(3);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Component ilk render olduğunda da çalıştırılsın

        return () => {
            window.removeEventListener('resize', handleResize); // Component unmount olduğunda event listener'ı temizle
        };
    }, []);

    return (
        <section className="">
            <div
                className="mx-auto mt-20"
            >
                <div className="max-w-7xl items-end justify-between sm:flex sm:pe-6 lg:pe-8 text-white">
                    <h2 className="max-w-xl lg:text-3xl  sm:text-lg font-bold tracking-tight ">
                        {t('mainPage.gamePanel.' + gameTypeName)}
                    </h2>
                </div>
                <div className="mt-5 mb-20 ">
                    <Swiper
                        speed={1500}
                        loop={true}
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={25}
                        slidesPerView={slidesPerView}
                    >
                        {(adminGames.length == 0 && !isLoaded) && (
                            <SwiperSlide >
                                <LoadingRankedGameMiniCard></LoadingRankedGameMiniCard>
                            </SwiperSlide>
                        )}
                        {adminGames.map((key, index) => (
                            <SwiperSlide key={index + "-slide"}>
                                <Link to={"/gameView/" + key.id}>
                                    <RankedGameMiniCard key={index} id={key.id} gameName={key.gameName} gameCreatorUserId={key.gameCreatorUserId} gameCreatorUserAvatarName={key.gameCreatorUserAvatarName}
                                        gameCreatorUserName={key.gameCreatorUserName} gameTypeName={key.gameType.gameTypeName} gamePreviewImage={key.gamePreviewImage} playerCount={key.playerCount} createDate={key.createDate}></RankedGameMiniCard>
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default RankedGame