import React from 'react'
import { Link } from 'react-router-dom'
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { getAvatarImageFromLocalStorage } from '../../../service/Avatar';

function RankedMiniGameCard(props) {
    const { id, gameName, gameCreatorUserId, gameCreatorUserName, gameTypeName, gamePreviewImage, gameCreatorUserAvatarName,admin } = props;
    return (

        <div href="#" className="block  hover:scale-105 duration-200 cursor-pointer">
            <img
                alt="Guess The Game Image"
                src={`data:image/jpeg;base64,${gamePreviewImage}`}
                className="h-40 w-full object-cover sm:h-40 lg:h-64 rounded-t-md"
            />

            <div className=' bg-zinc-800 rounded-b-md'>
                <div className=" text-center ">
                    <a href="#" className="block lg:text-base sm:text-sm  font-bold  text-white" tabIndex="0" role="link">{gameName}</a>
                </div>
                <div className="flex  items-center ">
                    <div className=' flex items-center mb-1 mx-5'>
                        <img
                            className="h-10 w-10 rounded-full  ring-2 ring-gray-400"
                            src={`data:image/jpeg;base64,${getAvatarImageFromLocalStorage(gameCreatorUserAvatarName)}`}
                            alt="Game Creator Avatar"
                        />
                        <Link to={"/rankedGames"}>
                            <div href="#" className="text-center lg:text-base sm:text-sm   text-white hover:underline ml-3" tabIndex="0" role="link">
                                {gameCreatorUserName} 
                                {admin === true ? <BsFillPatchCheckFill className="inline-block lg:text-base sm:text-sm text-sm  text-green-500 ml-2" /> : ""}
                                </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>




    )
}

export default RankedMiniGameCard