import React, { useEffect } from 'react'
import TopListTable from '../card/TopListTable'
import TodayGameCard from '../card/TodayGameCard'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function TopList() {
    
    const { t } = useTranslation();

   
    return (
        <div>
            <Helmet>
                <title>Guess The Game - Game Guess - Today's Game Guess Game and Top Player List</title>
                <meta name="description" content="Game Guess - Game the Anime. Enjoy the top game guessing game experience and compete to be on the top player list! You can find the game guess game of the day and the leaderboard of the best game guessers." />
            </Helmet>
            <div className="mx-auto  max-w-6xl lg:px-16 md:px-10 px-5   font-bodyFont">
                <h1 className="lg:text-4xl sm:text-xl font-bold  text-white my-10">
                    {t('topPlayerPage.title2')}
                </h1>
                <TodayGameCard></TodayGameCard>
                <span class="flex items-center  sm:px-20 lg:px-16 lg:text-4xl sm:text-xl font-bold justify-center  text-white my-10 ">
                    <span class="h-px flex-1 bg-white"></span>
                    <span class="shrink-0 px-6">{t('topPlayerPage.title1')}</span>
                    <span class="h-px flex-1 bg-white"></span>
                </span>

                <TopListTable></TopListTable>
            </div>
        </div>)
}

export default TopList