import React, { useEffect, useRef, useState } from 'react'
import { BsCheck2Circle } from 'react-icons/bs';
import { FaImage } from "react-icons/fa6";
import { RiCloseCircleFill } from "react-icons/ri";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ViewGameInfo from '../card/ViewGameInfo';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import SessionStatus from '../../../service/SessionStatus';
import { useToken } from '../../../service/context/TokenProvider';
import { Helmet } from 'react-helmet';

function CreateGame() {
    const navigate = useNavigate();
    const { tokenData } = useToken();
    const { userRoles, accessToken, subId } = tokenData
    const [gameTypes, setGameTypes] = useState([]);
    const [session, setSession] = useState(false);
    const [sectionAmount, setSectionAmount] = useState(5);
    const [gamePreviewImage, setGamePreviewImage] = useState();
    const { t } = useTranslation();

    const [game, setGame] = useState({
        gameName: "",
        gameCreatorUserId: subId,
        gameTypeId: "",
        gamePreviewImage: "",
        squareAmount: 1
    });
    const [sectionImages, setSectionImages] = useState([]);
    const [sectionNames, setSectionNames] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedResult, setSelectedResult] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const fillGameType = () => {
        axios.get('/gameType/getAll')
            .then(res => {
                setGameTypes(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const searchGameByName = (query) => {
        axios.get('https://api.rawg.io/api/games?key=8c414b993a2f4a068121321b654ca688&page_size=5&page=1&search_precise=true&exclude_additions=true&search=' + query.value)
            .then(res => {
                const updatedResults = [...searchResults];
                updatedResults[query.index] = res.data.results;
                setSearchResults(updatedResults)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const createGame = (e) => {
        e.preventDefault();

        // bolum isimlerini kontrol et
        let hasNull = false
        Object.keys(selectedResult).forEach((key) => {
            if (selectedResult[key] == null) {
                hasNull = true
            }
        })
        if (hasNull) {
            setError(t("createGamePage.errorText1"));
            return
        }

        // bolum fotograflarini kontrol et
        if (Object.keys(sectionImages).length != sectionAmount) {
            setError(t("createGamePage.errorText2"));
            return
        }

        // oyun fotosunu kontrol et
        if (game.gamePreviewImage == "") {
            setError(t("createGamePage.errorText3"));
            return
        }

        // oyunun katagorisini kontrol et
        if (game.gameTypeId == "") {
            setError(t("createGamePage.errorText4"));
            return
        }

        const formData = new FormData();

        Object.keys(game).forEach((key) => {
            formData.append(key, game[key]);
        });

        formData.append('token', accessToken)

        setIsLoading(true)
        axios.post('/game/add', formData, {
            headers: {
                Authorization: accessToken
            }
        })
            .then(async (res) => {
                await createSections(res.data.gameId)
            })
            .catch(err => {
                if (err.response.data.message === "Access Denied") {
                    setIsLoading(false);
                    setSession(t("session.text1"));
                    return
                } else if (err.response.data.message === "Image is null") {
                    setIsLoading(false)
                    setError(t("createGamePage.errorText8"))
                    return
                } else if (error.response.data.message === "Image format is not supported") {
                    setIsLoading(false)
                    setError(t("createGamePage.errorText7"))
                    return
                }
                console.log(err)
                setError(t("createGamePage.errorText5"))
                setIsLoading(false)
            })
    }

    const createSections = async (gameId) => {
        let hasError = false
        let loggedErrors = []
        let loggedIndexes = []
        let formData = null
        let index = 1
        for (const key of Object.keys(sectionNames)) {
            formData = new FormData();
            formData.append('gameId', gameId);
            formData.append('sectionCorrectAnswer', sectionNames[key]);
            formData.append('sectionImage', sectionImages[key]);
            formData.append('token', accessToken)
            try {
                await axios.post('/section/add', formData, {
                    headers: {
                        Authorization: accessToken
                    }
                })
            } catch (error) {
                loggedErrors.push(error)
                loggedIndexes.push(index)
                console.log(error)
                hasError = true
            }
            index += 1
        }

        let errShowed = false
        loggedErrors.forEach((error) => {
            if (error.response.data.message === "Image is null") {
                setIsLoading(false)
                setError(t("createGamePage.errorText8") + " Images: " + loggedIndexes.join(", "))
                errShowed = true
                return
            } else if (error.response.data.message === "Image format is not supported") {
                setIsLoading(false)
                setError(t("createGamePage.errorText7") + " Images: " + loggedIndexes.join(", "))
                errShowed = true
                return
            }
        })

        if (!errShowed && hasError) {
            setIsLoading(false)
            alert("Oyun oluşturulurken bir hata oluştu")
        }
        if (!hasError) {
            navigate('/userProfile/' + localStorage.getItem("signedUserId"));
        } else {
            axios.delete('/game/rollbackCreatedGame', {
                data: {
                    gameId: gameId,
                    token: accessToken
                },
                headers: {
                    Authorization: accessToken
                }
            })
                .then(res => {

                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const handleResultClick = (result, index) => {
        setSectionNames({ ...sectionNames, [`section-${index}`]: result })
        setSelectedResult({ ...selectedResult, [`section-${index}`]: result })
        setSearchResults([]);
    };

    const onGameInputChange = (e) => {
        setGame({ ...game, [e.target.name]: e.target.value });
    };

    const onGamePreviewImageInputChange = (e) => {
        const file = e.target.files[0];
        console.log(file)
        if (userRoles.includes("GxGx_S23SC") == true) {
            if (file?.type != "image/jpeg" && file?.type != "image/png" && file?.type != "image/gif") {
                setError(t("createGamePage.errorText7"));
                return
            }
        } else {
            if (file?.type != "image/jpeg" && file?.type != "image/png") {
                setError(t("createGamePage.errorText7"));
                return
            }
        }
        if (file.size > 10000000) {
            setError(t("createGamePage.errorText6"));
            return
        }
        setGame({ ...game, [e.target.name]: file });
        if (file) {
            setGamePreviewImage(URL.createObjectURL(file));
        }
    };

    const onSectionPreviewImageInputChange = (e) => {
        const file = e.target.files[0];
        if (file?.type != "image/jpeg" && file?.type != "image/png") {
            setError(t("createGamePage.errorText7"));
            return
        }
        if (file.size > 10000000) {
            setError(t("createGamePage.errorText6"));
            return
        }
        setSectionImages({ ...sectionImages, [e.target.name]: file });
    };

    const onInputChange = (e) => {
        setSectionAmount(e.target.value)
    };

    const onSectionNamesInputChange = (e, index) => {
        setSectionNames({ ...sectionNames, [`section-${index}`]: e.target.value })
        setSelectedResult({ ...selectedResult, [`section-${index}`]: null })
        setInputValue({ "value": e.target.value, "index": index })
    };
    const closeAlert = () => {
        setError(null);
    };

    useEffect(() => {
        fillGameType()
    }, [])

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(inputValue);
        }, 1000);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue]);

    useEffect(() => {
        if (debouncedValue && debouncedValue != "") {
            searchGameByName(debouncedValue)
        }
    }, [debouncedValue]);
    useEffect(() => {
        window.scrollTo(0, 0); // Sayfanın en üstüne scroll yap
    }, []);
  
    return (
        <div>
            <Helmet>
                <title>Game Guess - Create Guess The Game</title>
                <meta name="description" content=" Guess the Game - Game Guess. Enjoy the game guess gaming experience, and compete to be on the top player list! Create your own game and have fun crafting your own unique gaming experience! Guess the game from the pictures! The best game characters guess and game picture guess games are here!" />
            </Helmet>
            {isLoading ?
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-opacity-0 backdrop-filter backdrop-blur-sm z-10">
                    <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-4 h-40 w-40 sm:h-20 sm:w-20 mb-12"></div>
                </div>
                :
                null
            }
            {error && (
                <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-sm">
                    <div className="fixed inset-0 bg-black opacity-40 z-40"></div>
                    <div
                        role="alert"
                        className="rounded border-s-4 border-red-500 bg-zinc-900 p-4 relative z-50 max-w-xl mx-auto w-full"
                    >
                        <button
                            onClick={closeAlert}
                            className="absolute top-2 right-2 text-white focus:outline-none"
                        >
                            <span className="sr-only">Kapat</span>
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <strong className="block text-6xl mb-5 font-medium text-white">
                            :(
                        </strong>
                        <p className="mt-2 text-2xl text-white">
                            {error}
                        </p>
                    </div>
                </div>
            )}
            <div className="mx-auto max-w-7xl sm:px-20 lg:px-16  font-bodyFont">
                {session && (
                    <SessionStatus session={session}></SessionStatus>
                )}
                <div className="flex justify-center mx-auto my-10">
                    <p className='lg:text-3xl  sm:text-lg text-white'>{t("createGamePage.title1")} </p>

                </div>
                <div className="mx-auto max-w-3xl   ">

                    <form className=" " onSubmit={createGame}>

                        <div className=' '>

                            <div className="flex flex-col md:flex-row w-full my-2 md:gap-2 justify-center items-center">
                                <div className="flex-1 md:mr-2 mb-4 md:mb-0">
                                    <label htmlFor="HeadlineAct" className="block text-white my-2 lg:text-xl sm:text-sm">
                                        {t("createGamePage.title2")}
                                    </label>
                                    <select
                                        onChange={(e) => onGameInputChange(e)}
                                        name="gameTypeId"
                                        id="gameTypes"
                                        className="lg:w-full md:w-44 sm:80 w-80  py-3 px-2 appearance-none bg-zinc-900 text-white rounded-lg border border-gray-200 lg:text-xl sm:text-sm"
                                    >
                                        <option value="" disabled selected>
                                            {t("createGamePage.text1")}
                                        </option>
                                        {gameTypes.map((key, index) => (
                                            <option value={key.id} key={index}>
                                                {t('createGamePage.' + key.gameTypeName)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex-1 md:flex items-center">
                                    <div className="flex-1 mb-4 md:mb-0 md:mr-2">
                                        <label htmlFor="section" className="block text-white my-2 lg:text-xl sm:text-sm">
                                            {t("createGamePage.title3")}
                                        </label>
                                        <select
                                            onChange={(e) => onInputChange(e)}
                                            name="sectionAmount"
                                            id="section"
                                            className="lg:w-full md:w-44 sm:80 w-80 py-3 px-2 appearance-none bg-zinc-900 text-white rounded-lg border border-gray-200 lg:text-xl sm:text-sm"
                                        >
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                        </select>
                                    </div>
                                    <div className="flex-1 md:ml-2">
                                        <div className="flex-1 mb-4 md:mb-0 md:mr-2">
                                            <label htmlFor="section" className="flex justify-between text-white my-2 lg:text-xl sm:text-sm">
                                                {t("createGamePage.title4")}
                                                <ViewGameInfo></ViewGameInfo>
                                            </label>
                                            <select
                                                onChange={(e) => onGameInputChange(e)}
                                                name="squareAmount"
                                                id="squareAmount"
                                                className="lg:w-full md:w-44 sm:80 w-80 py-3 px-2 appearance-none bg-zinc-900 text-white rounded-lg border border-gray-200 lg:text-xl sm:text-sm"
                                            >
                                                <option value="1">1</option>
                                                <option value="4">4</option>
                                                <option value="9">9</option>
                                                <option value="16">16</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="flex flex-col md:flex-row w-full my-2 md:gap-2 justify-center items-center">
                            <label htmlFor="HeadlineAct" className="block  text-center  lg:text-xl sm:text-sm text-white mt-4 mb-2 ">
                                {t("createGamePage.title5")}
                            </label>
                            <input
                                onChange={(e) => onGameInputChange(e)}
                                placeholder={t("createGamePage.text2")}
                                required
                                type="text"
                                id="gameName"
                                name="gameName"
                                maxLength={40}
                                className=" lg:w-full sm:w-80 w-80  appearance-none py-3 px-2 text-white lg:text-xl sm:text-sm bg-zinc-900 border border-gray-200 rounded-md   focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring" />
                        </div>
                        <div className="flex flex-col items-center">
                            <label htmlFor="HeadlineAct" className="block  lg:text-xl sm:text-sm text-white  ">
                                {t("createGamePage.title6")}

                            </label>
                            <div>
                                <label htmlFor="dropzone-file" className="flex flex-col items-center sm:w-24 lg:w-56 w-24 p-5 mt-2 text-center  border-2  border-dashed cursor-pointer bg-gray-900 border-gray-700 rounded-xl">
                                    {
                                        gamePreviewImage ? <img src={gamePreviewImage} alt="Selected" />
                                            :
                                            <>
                                                <FaImage className='lg:h-8 lg:w-8 sm:h-4 sm:w-4 text-white'></FaImage>
                                                <h2 className="mt-1  lg:text-base sm:text-xs tracking-wide  text-gray-200">{t("createGamePage.text3")} </h2>
                                            </>
                                    }

                                    <input id="dropzone-file" type="file" className="hidden" name="gamePreviewImage" onChange={(e) => onGamePreviewImageInputChange(e)} accept={userRoles.includes("ROLE_ADMIN") ? ".jpg, .jpeg, .png, .gif" : ".jpg, .jpeg, .png"} />
                                </label>
                            </div>
                        </div>
                        <section className="lg:px-0 px-2">

                            <label htmlFor="HeadlineAct" className="block  lg:text-xl sm:text-sm text-white my-2 mt-7">
                                {t("createGamePage.title7")}

                            </label>
                            <div className=" border border-gray-200 rounded-md ">
                                <table className="min-w-full divide-y  divide-gray-700">
                                    <tbody className="bg-zinc-900  ">
                                        {
                                            Array.from({ length: sectionAmount }, (_, i) => (
                                                <tr key={i + "-tr"}>
                                                    <td className="lg:px-4 lg:py-4 sm:px-2 sm:py-2 px-2 py-2 text-sm font-medium text-gray-700 whitespace-nowrap">
                                                        <div className="inline-flex items-center gap-x-3">
                                                            <p className=' text-white lg:text-xl sm:text-xs'>{i + 1} - </p>
                                                            <div>
                                                                <label className="flex flex-col items-center sm:w-24 lg:w-56 w-24  p-5  text-center border-2 border-dashed cursor-pointer bg-gray-900 border-gray-700 rounded-xl">
                                                                    {
                                                                        sectionImages['section-' + i] ? <img src={URL.createObjectURL(sectionImages['section-' + i])} alt="Selected" />
                                                                            :
                                                                            <>
                                                                                <FaImage className='lg:h-8 lg:w-8 sm:h-4 sm:w-4 text-white'></FaImage>
                                                                                <h2 className="mt-1 lg:text-base sm:text-xs tracking-wide  text-gray-200">{t("createGamePage.text4")}</h2>
                                                                            </>
                                                                    }
                                                                    <input name={'section-' + i} type="file" className="hidden" onChange={(e) => onSectionPreviewImageInputChange(e)} accept=".jpg, .jpeg, .png" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="lg:px-6 sm:px-1 text-sm font-medium text-gray-700 whitespace-nowrap relative">

                                                        <input
                                                            onChange={(e) => onSectionNamesInputChange(e, i)}
                                                            placeholder={t("createGamePage.text2")}
                                                            required
                                                            type="search"
                                                            id="userName"
                                                            name={'section-' + i}
                                                            maxLength={30}
                                                            value={sectionNames['section-' + i]}
                                                            autoComplete="off"
                                                            className=" lg:w-96 sm:w-60 w-60 items-start justify-start py-2 px-2  text-white lg:text-lg sm:text-sm bg-zinc-900 border border-gray-200 rounded-md    focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring" />

                                                        {
                                                            selectedResult['section-' + i] != null ?
                                                                <span className='absolute right-16 top-1/2 transform -translate-y-1/2'>
                                                                    <BsCheck2Circle className='h-6 w-6 text-green-400'></BsCheck2Circle>
                                                                </span>
                                                                :
                                                                sectionNames['section-' + i] != null ?
                                                                    <span className='absolute right-16 top-1/2 transform -translate-y-1/2'>
                                                                        <RiCloseCircleFill className='h-6 w-6 text-red-400'></RiCloseCircleFill>
                                                                    </span>
                                                                    :
                                                                    null
                                                        }

                                                        {searchResults[i] != null && (
                                                            <div className="z-20 absolute lg:w-96 sm:w-60  w-44 overflow-y-auto max-h-36 border rounded-md shadow-md text-white lg:text-lg sm:text-sm bg-zinc-900  border-gray-200   focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring">
                                                                <ul className="p-2">
                                                                    {searchResults[i].map((result, index) => (
                                                                        <li
                                                                            key={index}
                                                                            className="py-1 cursor-pointer"
                                                                            onClick={() => handleResultClick(result.name, i)}
                                                                        // bunun disindaki bir yere tiklaninca da kapanmasi lazim onclick benzeri bir sey varsa onunla yap


                                                                        >
                                                                            {result.name}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='text-left lg:px-0 px-2'>
                                <label htmlFor="HeadlineAct" className="block lg:text-lg sm:text-sm font-medium text-white mt-4">
                                    {t("createGamePage.text5")}
                                </label>
                                <label htmlFor="HeadlineAct" className="block lg:text-lg sm:text-sm font-medium text-white my-1">
                                    {t("createGamePage.text6")}
                                </label>
                                <label htmlFor="HeadlineAct" className="block lg:text-lg sm:text-sm font-medium text-white my-1">
                                    {t("createGamePage.text7")}
                                </label>
                            </div>
                        </section>
                        <div className='flex justify-center'>
                            <button className=' mt-4 lg:w-full sm:w-96 hover:bg-red-600 duration-200   lg:text-xl sm:text-sm  border border-red-500 bg-red-500 px-3 py-2 rounded-lg text-white' type='submit'>{t("createGamePage.buttonText")} </button>
                        </div>

                        <div className="my-2 lg:text-lg sm:text-sm text-sm text-gray-400 text-center">
                            Powered by <a href='https://rawg.io/apidocs' target='_blank' className='hover:underline'> RAWG.IO </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreateGame