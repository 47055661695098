import React, { useEffect, useState } from 'react'
import { FaPowerOff, FaForward } from "react-icons/fa6";
import { FiCheck, FiX, FiEdit3, FiCoffee, FiEye } from "react-icons/fi";

import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useToken } from '../../../service/context/TokenProvider';
import EndGame from './EndGame';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function Game() {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { gameId } = useParams();
    const { tokenData } = useToken();
    const { subId, accessToken } = tokenData;

    const [section, setSections] = useState([])
    const [numSquares, setNumSquares] = useState(2);
    const [randomIndexes, setRandomIndexes] = useState([]);
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0)
    const [totalScore, setTotalScore] = useState(0);
    const [answerClicked, setAnswerClicked] = useState(false);
    const [guestUserId, setGuestUserId] = useState(null);
    const [lastClickTime, setLastClickTime] = useState(0);
    const [isButtonClickable, setIsButtonClickable] = useState(true);
    const [quitError, setQuitError] = useState(null);
    const [navigateToEndGame, setNavigateToEndGame] = useState(false);
    const [showEndGame, setShowEndGame] = useState(false);
    const [wrongAnswersCount, setWrongAnswersCount] = useState(0);
    const [trueAnswersCount, setTrueAnswersCount] = useState(0);
    const [animeIsWatched, setAnimeIsWatched] = useState(null);
    const [showPlanToWatch, setShowPlanToWatch] = useState(null);
    const [userAnswerState, setUserAnswerState] = useState(null);
    const [isGamePlayed, setIsGamePlayed] = useState(false);
    const [handleSquearesFlag, setHandleSquaresFlag] = useState(false);
    const [handleSquares, setHandleSquares] = useState(0);
    const [sectionScore, setSectionScore] = useState(0);
    const [sectionScoreControl, setSectionScoreControl] = useState(false);
    const [isCounting, setIsCounting] = useState(false);
    const [countdown, setCountdown] = useState(5);
    const [firstParts, setFirstParts] = useState(false);
    const [parts, setParts] = useState([]);
    const [isButtonClickableSquare, setIsButtonClickableSquare] = useState(true)
    const [lastSquareButton, setLastClickSquareButton] = useState(0);
    const [isSectionsFetched, setIsSectionsFetched] = useState(false);
    const [error, setError] = useState(false);

    //Bölüm geçtikçe resimleri değiştiriyoruz
    const handleImageLoad = () => {
        const img = new Image();
        const currentSection = section.sections[currentSectionIndex];
        if (currentSection && currentSection.sectionImage) {
            img.src = `data:image/jpeg;base64,${currentSection.sectionImage}`;
            if (numSquares != 1) {
                img.onload = () => {
                    const partsArray = splitImage(img, numSquares);
                    setParts(partsArray);
                    setHandleSquares(numSquares * numSquares - 1);
                    setHandleSquaresFlag(true);
                    setFirstParts(true);
                };
            }
        }
    };

    // Resmi parçalara ayırıyoruz
    const splitImage = (img, numSquares) => {
        const { width, height } = img;
        const canvasWidth = width / numSquares;
        const canvasHeight = height / numSquares;
        const canvasArray = [];

        for (let i = 0; i < numSquares * numSquares; i++) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            // Siyah rengi kullanarak parçayı doldur
            ctx.fillStyle = "black";
            ctx.fillRect(0, 0, canvasWidth, canvasHeight);

            canvasArray.push(canvas.toDataURL());
        }

        return canvasArray;
    };

    const restoreOriginalPart = (index) => {
        const originalImage = new Image();
        const currentSection = section.sections[currentSectionIndex];
        if (currentSection && currentSection.sectionImage) {
            originalImage.src = `data:image/jpeg;base64,${currentSection.sectionImage}`;
            originalImage.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = originalImage.width;
                canvas.height = originalImage.height;

                const sx = (index % Math.sqrt(numSquares * numSquares)) * canvas.width / numSquares;
                const sy = Math.floor(index / Math.sqrt(numSquares * numSquares)) * canvas.height / numSquares;

                ctx.drawImage(originalImage, sx, sy, canvas.width / numSquares, canvas.height / numSquares, 0, 0, canvas.width, canvas.height);

                const updatedParts = [...parts];
                updatedParts[index] = canvas.toDataURL();
                setParts(updatedParts);
            };
        }
    };

    const handleRandomOpen = () => {

        const currentTime = new Date().getTime();
        if (currentTime - lastSquareButton < 500) {
            return;
        }
        setLastClickSquareButton(currentTime);

        if (handleSquearesFlag) {
            setHandleSquares((prevSquares) => prevSquares - 1);
        }
        restoreOriginalPart(randomIndexes[0]);
        setRandomIndexes(randomIndexes.slice(1));
    };

    const handleSquareButtonClick = () => {
        setIsButtonClickableSquare(false);
        setTimeout(() => {
            if (handleSquares != 1) {
                setIsButtonClickableSquare(true);
            }
        }, 500);
        handleRandomOpen();
    };

    const getSections = async () => {
        await axios.get('/section/getAll/game/' + gameId, {
            headers: {
                Authorization: accessToken
            }
        })
            .then((response) => {
                setSections(response.data)
                setNumSquares(Math.round(Math.sqrt(response.data.squareAmount)));
                setIsSectionsFetched(true);
            })
            .catch((error) => {
                setIsSectionsFetched(true);
                setError(error)
            })
    }

    const clearShadows = () => {
        const buttons = document.querySelectorAll('button');
        buttons.forEach((button) => {
            button.classList.remove('shadow-green-500');
            button.classList.remove('shadow-red-500');
        });
    };

    const closeAlert = () => {
        setError(null);
    };

    const startCountdown = () => {
        if (countdown === 0) {
            navigate('/')
        }
        if (guestUserId) {
            navigate('/')
        }
        if (!isCounting) {
            setIsCounting(true);
            setQuitError("Are you sure you want to quit? Otherwise, your game will end, and you will receive your current score.");
            return
        }
    };

    const handlePassClick = () => {
        setAnimeIsWatched(null)
        setShowPlanToWatch(null)
        setAnswerClicked(false);
        setIsButtonClickableSquare(true);
        setIsButtonClickable(false);
        setSectionScoreControl(false)
        setTimeout(() => {
            setIsButtonClickable(true);
        }, 200);
        const currentTime = new Date().getTime();
        if (currentTime - lastClickTime < 200) {
            return;
        }
        setLastClickTime(currentTime);
        if (currentSectionIndex === section.sections.length - 1) {
            setNavigateToEndGame(true);
        } else {
            setCurrentSectionIndex((prev) => prev + 1)
            clearShadows();
        }
    }

    const onNextSectionClick = (e) => {
        if (!answerClicked) {
            setAnswerClicked(true);
            const userAnswer = e.target.innerText;

            clearShadows();

            axios.post('/section/checkanswer', {
                "gameId": gameId,
                "sectionNumber": currentSectionIndex,
                "userAnswer": userAnswer,
                "userId": subId == null ? guestUserId : subId,
                "remainingSquare": handleSquares,
                "gamePlayed": isGamePlayed,
                "token": accessToken,
                "guest": subId == null ? true : false
            }, {
                headers: {
                    Authorization: accessToken
                }
            })
                .then(res => {
                    setUserAnswerState(userAnswer);
                    if (res.data.message === "Correct answer") {
                        setSectionScoreControl(true)
                        setTrueAnswersCount((prev) => prev + 1);
                        setTotalScore(res.data.score);
                        e.target.classList.add('shadow-green-500');
                    } else if (res.data.message === "Wrong answer") {
                        setSectionScoreControl(true)
                        setWrongAnswersCount((prev) => prev + 1);
                        e.target.classList.add('shadow-red-500');
                        const buttons = document.querySelectorAll('button');
                        buttons.forEach((button) => {
                            if (button.innerText === res.data.correctAnswer) {
                                button.classList.add('shadow-green-500');
                            }
                        });
                    } else if (res.data.message == "Game not started") {
                        setError("Game session expired. Please start the game again.");
                        setTimeout(() => {
                            navigate('/');
                        }, 3000);
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        }
    };

    const closeQuitlert = () => {
        setQuitError(null);
    };

    useEffect(() => {
        let timer;
        if (isCounting && countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsCounting(false);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [countdown, isCounting]);

    useEffect(() => {
        getSections()
    }, [])

    useEffect(() => {
        if (navigateToEndGame) {
            axios.post('/section/finish', {
                "gameId": gameId,
                "userId": subId == null ? guestUserId : subId,
                "gamePlayed": isGamePlayed,
                "token": accessToken,
                "guest": subId == null ? true : false
            }, {
                headers: {
                    Authorization: accessToken
                }
            })
                .then(res => {
                    if (res.data.message == "Game finished") {
                        //navigate('/endGame/' + gameId, { state: { "totalScore": res.data.score, trueAnswersCount, wrongAnswersCount, "navigated": true } });
                        //navigate('/userProfile/' + subId)
                        setShowEndGame(res.data);
                    } else if (res.data.message == "Game not found") {
                        setError("Game session expired. Please start the game again.");
                        setTimeout(() => {
                            navigate('/');
                            //navigate('/userProfile/' + subId)
                        }, 3000);
                    }
                })
        }
    }, [navigateToEndGame]);

    useEffect(() => {
        // Sayfa yenilendiğinde verileri yeniden ayarla
        if (location.state) {
            const { isGamePlayedState, navigated, guestUserId } = location.state;
            if (!navigated) {
                navigate('/');
            }
            setIsGamePlayed(isGamePlayedState);
            setGuestUserId(guestUserId);
        } else {
            navigate('/');
        }

        const handlePageRefresh = (event) => {
            event.preventDefault();
            navigate('/game/' + gameId, { state: { "isGamePlayedState": true } });
        };

        window.addEventListener('beforeunload', handlePageRefresh);

        return () => {
            window.removeEventListener('beforeunload', handlePageRefresh);
        };
    }, [location.state]);

    useEffect(() => {
        if (!sectionScoreControl) {
            setSectionScore(100 + (handleSquares * 10))
        }
    }, [handleSquares]);

    useEffect(() => {
        if (handleSquares === 0) {
            setHandleSquaresFlag(false);
        }
    }, [handleSquares]);

    useEffect(() => {
        if (isSectionsFetched) {
            if (numSquares != 1) {
                handleImageLoad()
            } else {
                handleImageLoad()
            }
        }

    }, [currentSectionIndex, numSquares, section]);

    useEffect(() => {
        if (firstParts) {
            setFirstParts(false);
            function getRandomInt(max) {
                return Math.floor(Math.random() * max);
            }
            const randomSqu = getRandomInt(numSquares * numSquares)
            restoreOriginalPart(randomSqu)

            const randomNums = [];

            while (randomNums.length < numSquares * numSquares - 1) {
                const randomIndex = Math.floor(Math.random() * numSquares * numSquares);
                if (!randomNums.includes(randomIndex) && randomIndex !== randomSqu) {
                    randomNums.push(randomIndex);
                }

            }

            setRandomIndexes(randomNums);
        }

    }, [firstParts, section]);
   

    if (!isSectionsFetched) {
        return (
            <div className='text-white border-red-500 rounded-lg'>
                <div className="flex  h-screen justify-center items-center prounded-lg  ">
                    <div className="">
                        <div className="border-t-transparent border-solid animate-spin rounded-full border-red-500  border-8 h-28 w-28">
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <section className=" font-bodyFont px-5">
                <Helmet>
                    <title>Guess The Game - Game Guess</title>
                    <meta name="description" content=" Guess the Game - Game Guess. Enjoy the game guess gaming experience, and compete to be on the top player list! Guess the game from the pictures! The best game characters guess and game picture guess games are here!" />
                </Helmet>
                {quitError && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-sm">
                        <div className="fixed inset-0 bg-zinc-800  opacity-40 z-40"></div>
                        <div
                            role="alert"
                            className="rounded border-s-4 border-red-500 bg-zinc-900 p-4 relative z-50 max-w-xl mx-auto w-full"
                        >
                            <button
                                onClick={closeQuitlert}
                                className="absolute top-2 right-2 text-white focus:outline-none"
                            >
                                <span className="sr-only">Kapat</span>
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                            <strong className="block lg:text-6xl sm:text-2xl mb-5 font-medium text-white">
                                :(
                            </strong>
                            <p className="mt-2 lg:text-xl sm:text-sm text-white">
                                {quitError}
                            </p>
                            <div className='mt-2 flex justify-end'>
                                <button onClick={closeQuitlert} className="hover:bg-red-600 duration-200  lg:text-xl sm:text-sm   bg-red-500 px-3 py-2 rounded-lg text-white">OK</button>
                            </div>
                        </div>
                    </div>
                )}

                {error && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-sm">
                        <div className="fixed inset-0 bg-zinc-800  opacity-40 z-40"></div>
                        <div
                            role="alert"
                            className="rounded border-s-4 border-red-500 bg-zinc-900 p-4 relative z-50 max-w-xl mx-auto w-full"
                        >
                            <button
                                onClick={closeAlert}
                                className="absolute top-2 right-2 text-white focus:outline-none"
                            >
                                <span className="sr-only">Kapat</span>
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                            <strong className="block lg:text-6xl sm:text-2xl mb-5 font-medium text-white">
                                :(
                            </strong>
                            <p className="mt-2 lg:text-xl sm:text-sm text-white">
                                {error}
                            </p>
                        </div>
                    </div>
                )}

                <div className='text-white lg:text-3xl sm:text-lg text-base lg:p-6 sm:p-5 px-2 py-2'>
                    <p className=" ">
                        {t("game.text2")} <span className='text-red-500'>{totalScore}</span>
                    </p>
                    <p className="mt-2">
                        {t("game.text3")} <span className='text-red-500'>{sectionScore}</span>
                    </p>
                </div>

                <div className='flex items-center justify-center '>
                    <div class="  max-w-6xl lg:mt-0 mt-20">
                        <div className=' flex justify-between'>
                            <p className=" lg:text-5xl sm:text-lg text-base text-white ">
                                {currentSectionIndex + 1}/{section.sections.length}
                            </p>

                            {isCounting ? (
                                <p className="p-2 text-red-500 duration-500 lg:text-4xl sm:text-sm">
                                    {countdown}
                                </p>
                            ) :
                                <FaPowerOff className='lg:text-4xl sm:text-xl text-xl mt-2 text-red-500 hover:text-red-600 cursor-pointer' onClick={startCountdown}></FaPowerOff>
                            }
                        </div>
                        <div className="flex justify-center my-5 ">
                            {
                                numSquares === 1 ?
                                    <div className=''>
                                        <img className=" w-auto  lg:h-96 md:h-80 sm:h-60 h-60" src={`data:image/jpeg;base64,${section.sections[currentSectionIndex].sectionImage}`} />
                                    </div>
                                    :
                                    numSquares === 2 ?
                                        <div className='grid grid-cols-2'>
                                            {
                                                parts.map((part, index) => (
                                                    <div key={index} className="">
                                                        <img className=" w-auto lg:h-44 md:h-36 sm:h-28 h-20" src={part} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        :
                                        numSquares === 3 ?
                                            <div className='grid grid-cols-3'>
                                                {
                                                    parts.map((part, index) => (
                                                        <div key={index} className="">
                                                            <img className=" w-auto  lg:h-36 md:h-28 sm:h-20 h-16" src={part} />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            :
                                            numSquares === 4 ?
                                                <div className='grid grid-cols-4'>
                                                    {
                                                        parts.map((part, index) => (
                                                            <div key={index} className="">
                                                                <img className=" w-auto  lg:h-36 md:h-28 sm:h-16 h-16" src={part} />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                :
                                                null
                            }
                        </div>

                        <div className="grid lg:grid-cols-2  md:grid-cols-2 sm:grid-cols-1 gap-4  w-full ">
                            {
                                section.sections[currentSectionIndex].sectionAnswers.map((option, index) => (
                                    index === 0 ?
                                        <button
                                            className={`bg-zinc-800 hover:bg-zinc-700 text-white px-4 py-2 rounded-lg duration-200  shadow-lg`}
                                            onClick={(e) => onNextSectionClick(e)}
                                        >{option}</button>
                                        :
                                        index === 1 ?
                                            <button
                                                className={`bg-zinc-800 hover:bg-zinc-700 text-white px-4 py-2  rounded-lg  duration-200 shadow-lg`}
                                                onClick={(e) => onNextSectionClick(e)}
                                            >{option}</button>
                                            :
                                            index === 2 ?
                                                <button
                                                    className={`bg-zinc-800 hover:bg-zinc-700 text-white px-4 py-2  rounded-lg  duration-200 shadow-lg`}
                                                    onClick={(e) => onNextSectionClick(e)}
                                                >{option}</button>
                                                :
                                                index === 3 ?
                                                    <button
                                                        className={`bg-zinc-800 hover:bg-zinc-700 text-white px-4 py-2 rounded-lg  duration-200 shadow-lg`}
                                                        onClick={(e) => onNextSectionClick(e)}
                                                    >{option}</button>
                                                    :
                                                    null
                                ))
                            }
                        </div>
                        {
                            numSquares != 1 ?
                                <div className='flex justify-center mt-10'>
                                    <button
                                        onClick={handleSquareButtonClick}
                                        className={` flex items-center align-middle rounded-lg lg:text-lg sm:text-sm ${isButtonClickableSquare ? 'bg-rose-600 hover:animate-pulse' : 'bg-gray-500'} lg:px-3 lg:py-2 px-2 py-2 font-medium shadow-sm  shadow-slate-400 text-white  focus:outline-none`}
                                        disabled={!isButtonClickableSquare}
                                    >
                                        {t("game.buttonText4")}
                                        <FiEye className=' w-6 h-6  ml-2 '></FiEye>
                                    </button>
                                </div>
                                :
                                null
                        }
                        <div className='flex justify-center mt-5'>
                            <FaForward
                                onClick={handlePassClick}
                                className={`lg:text-5xl sm:text-xl text-xl mt-1 ${isButtonClickable ? 'text-blue-500 hover:text-blue-600 cursor-pointer' : 'text-gray-500'} `}
                                disabled={!isButtonClickable}
                            ></FaForward>
                        </div>
                        <EndGame showEndGame={showEndGame} gameId={gameId} trueAnswersCount={trueAnswersCount} wrongAnswersCount={wrongAnswersCount} sectionLength={section.sections.length}></EndGame>
                    </div>
                </div>
            </section>

        )
    }
}

export default Game