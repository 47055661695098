import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Error() {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = "Game Guess - Guess The Game - Error Page";
        return () => {
            document.title = "Game Guess - Guess The Game";
        };
    }, []);
    return (
        <div className="grid h-screen bg-zinc-900 place-content-center font-bodyFont">
            <div className="text-center">
                <h1 className="font-black text-gray-200 text-9xl">404</h1>
                <p className="text-4xl font-bold tracking-tight text-red-500  mb-2">
                    GaGu Support
                </p>
                <p className="mt-4 text-gray-500 text-2xl">{t("errorPage.text1")} </p>
                <Link to={"/"}>
                    <button className=" text-sm px-4 py-2 mt-3 font-medium tracking-wide text-white transition-colors duration-200 transform border-2 border-red-500 hover:border-white bg-red-500 rounded hover:bg-red-500 focus:outline-none focus:ring focus:ring-cyan-300 focus:ring-opacity-100">

                        {t("errorPage.buttonText")}
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default Error