import React, { useEffect } from 'react'
import howtoplay from '../../assets/howtoplay.png'
import { useTranslation } from 'react-i18next';

function HowToPlay() {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = "Guess The Game - Game Guess - How To Play";
        return () => {
            document.title = "Guess The Game - Game Guess ";
        };
    }, []);
    return (
        <section className="mx-auto max-w-7xl sm:px-20 lg:px-10  px-5 font-bodyFont">
            <div className="container px-5 py-5 mx-auto">

                <div className="   rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
                    {/*body*/}
                    {howtoplay === null ?
                        <div className='text-white border-zinc-600 rounded-lg justify-center' >
                            <div className="flex  h-screen justify-center items-center prounded-lg  ">
                                <div className="">
                                    <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-2 h-14 w-14">
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className=' flex justify-center'>
                            <img
                                alt="Guess The Game Image"
                                src={howtoplay}
                                className="w-full ring-2  ring-zinc-600"
                            />
                        </div>
                    }
                    <p className='lg:text-4xl md:text-3xl sm:text-xl  text-xl text-red-500  font-bodyFont text-center  cursor-default my-7'>
                        {t('hotToPlay.title1')}
                    </p>
                    <div className='text-left'>
                        <div className=''>
                            <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm  font-medium text-white mt-4">
                                *{t('hotToPlay.text5')}
                            </p>
                            <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm  font-medium text-white mt-4">
                                *{t('hotToPlay.text6')}
                            </p>
                            <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm  font-medium text-white mt-4">
                                *{t('hotToPlay.text1')}
                            </p>
                            <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm  font-medium text-white mt-4">
                                *{t('hotToPlay.text2')}
                            </p>

                            <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm font-medium text-white mt-4">
                                *{t('hotToPlay.text3')}
                            </p>
                            <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm font-medium text-red-500 mt-2">
                                *{t('hotToPlay.text4')}
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </section>

    )
}

export default HowToPlay