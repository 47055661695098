import axios from 'axios';
import React, { useEffect, useState } from 'react'
import TrendGameCard from './TrendGameCard'
import LoadingTrendGameCard from './LoadingTrendGameCard';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function TrendGamePanel() {
    const [trendGames, setTrendGames] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const { t } = useTranslation();


    const topTrendGames = async () => {
        await axios.get('/game/topTrendGames?limit=3')
            .then((response) => {
                setTrendGames(response.data)
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }

    useEffect(() => {
        topTrendGames()
    }, [])

    return (
        <div>
            <div className='flex mt-20'>
                <h1 className="lg:text-3xl sm:text-xl font-bold  text-white ">
                    {t('mainPage.gamePanel.title2')}
                </h1>
            </div>
            <div className=' grid lg:grid-cols-3 mt-10 sm:grid-cols-1 gap-4'>
                {(trendGames.length == 0 && !isLoaded) && (
                    <>
                        <LoadingTrendGameCard></LoadingTrendGameCard>
                        <LoadingTrendGameCard></LoadingTrendGameCard>
                        <LoadingTrendGameCard></LoadingTrendGameCard>
                    </>
                )}

                {trendGames.map((key, index) => (
                    <Link to={"/gameView/" + key.id} key={index}>
                        <TrendGameCard id={key.id} gameName={key.gameName} gameCreatorUserId={key.gameCreatorUserId} gameCreatorUserName={key.gameCreatorUserName} gameTypeName={key.gameTypeName} gamePreviewImage={key.gamePreviewImage} playerCount={key.playerCount} gameCreatorUserAvatarName={key.gameCreatorUserAvatarName} isVerified={key.verified} admin={key.admin}></TrendGameCard>
                    </Link>
                ))}

            </div>
        </div>
    )
}

export default TrendGamePanel