import axios from 'axios';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function GuestView(props) {
    const { gameId } = props
    const { t } = useTranslation();
    const [showModal, setShowModal] = React.useState(false);
    let navigate = useNavigate();

    const navigateToGame = () => {
        let guestUserId = localStorage.getItem('guestId')
        if (!guestUserId) {
            guestUserId = Math.floor(Math.random() * 90000000) + 10000000
            localStorage.setItem('guestId', guestUserId)
        }
        axios.post('/guestplayed/add?gameId=' + gameId + '&guestId=' + guestUserId)
        .then((response) => {
            axios.post('/section/startgame', {
                gameId: gameId,
                userId: guestUserId,
                token: null,
                guest: true
            })
                .then((response) => {
                    sessionStorage.setItem('guestGamePlayedCount', parseInt(sessionStorage.getItem('guestGamePlayedCount')) + 1)
                    setShowModal(false)
                    navigate('/game/' + gameId, { state: { "isGamePlayedState": true, "navigated": true, "guestUserId": guestUserId } });
                })
        })
    }
    const closeQuitlert = () => {
        setShowModal(false);
    };


    return (
        <>
            <div
                onClick={() => {
                    let guestPlayedCount = sessionStorage.getItem('guestGamePlayedCount')
                    if (!guestPlayedCount) {
                        sessionStorage.setItem('guestGamePlayedCount', 0)
                        guestPlayedCount = 0
                    }
                    if (parseInt(guestPlayedCount) == 0) {
                        setShowModal(true)
                    } else if (parseInt(guestPlayedCount) >= 4) {
                        setShowModal(true)
                        sessionStorage.setItem('guestGamePlayedCount', 0)
                    }
                    else {
                        navigateToGame()
                    }
                }}
                className='flex text-center mx-auto flex-wrap w-full'
                type="button">
                <button
                    className="group relative w-full inline-block overflow-hidden border border-red-500 px-8 py-4 focus:outline-none rounded-lg"
                >
                    <span
                        className="absolute  inset-y-0 left-0 w-[2px] rounded-md duration-200 bg-red-500  transition-all group-hover:w-full group-active:bg-red-500"
                    ></span>
                    <span
                        className="relative text-lg font-medium text-white transition-colors group-hover:text-white"
                    >
                        {t("gameView.buttonText2")}
                    </span>
                </button>
            </div>
            {showModal ? (
                <div className="fixed inset-0 flex items-center justify-center z-50 px-4 backdrop-blur-sm">
                    <div className="fixed inset-0 bg-black opacity-40 z-40"></div>
                    <div className="rounded border-s-4 border-red-500 bg-zinc-900 p-4 relative z-50 max-w-xl mx-auto w-full">
                        {/* Close button */}
                        <button
                            onClick={() => {
                                closeQuitlert()
                            }}
                            className="absolute top-2 right-2 text-white cursor-pointer"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>

                        <p className="mt-2 lg:text-xl sm:text-base text-base text-white">{t("guestView.text1")}</p>

                        <div className="flex items-center justify-end mt-4 w-full rounded-b">
                            <button
                                onClick={() => {
                                    navigateToGame();
                                }}
                                className="ring-1 ring-white px-3 py-3 hover:bg-zinc-800 duration-200 rounded-lg text-white"
                            >
                                {t("guestView.buttonText1")}
                            </button>
                            <button
                                onClick={() => {
                                    navigate("/login");
                                }}
                                className="duration-200 bg-red-500 hover:bg-red-600 px-4 py-3 rounded-lg text-white ml-4"
                            >
                                {t("guestView.buttonText2")}
                            </button>
                        </div>
                    </div>
                </div>

            ) : null}
        </>
    )
}
