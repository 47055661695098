import axios from 'axios'
import React, { useEffect, useState } from 'react'
import AddCard from '../../../service/AddCard'
import MiniGamePanel from '../card/MiniGamePanel'
import NormalGamePanel from '../card/NormalGamePanel'
import RankedGamePanel from '../card/RankedGamePanel'
import TrendGamePanel from '../card/TrendGamePanel'
import { initializeAvatars } from '../../../service/Avatar'
import { t } from 'i18next'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { FaLaptop, FaMobileScreen, FaTabletScreenButton, FaUserLargeSlash, FaUserGear, FaTag, FaCrown, FaGem, FaGamepad, FaImage } from "react-icons/fa6";
import { useToken } from '../../../service/context/TokenProvider'
import VsAlertCard from '../card/VsAlertCard'


function Home() {
    const [gameType, setGameType] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const { tokenData, resetTokenData } = useToken();
    const { subId } = tokenData;
    const { t } = useTranslation();


    const getAllGameTypePanel = async () => {
        await axios.get('/gameType/getAll')
            .then((response) => {
                setGameType(response.data)
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }

    useEffect(() => {
        initializeAvatars()
        getAllGameTypePanel()
    }, [])
    
    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen flex-col">
                <div className="text-4xl font-bold text-red-500 mb-4">{t("mainPage.errorText1")} </div>
                <div className="text-2xl font-bold text-red-500">{t("mainPage.errorText2")}</div>
            </div>
        )

    } else if (!isLoaded) {

        return (
            <div className='text-white border-zinc-600 rounded-lg'>
                <div className="flex  justify-center items-center mt-20 prounded-lg  ">
                    <div className="">
                        <div className="border-t-transparent border-solid animate-spin rounded-full border-red-500 border-8 h-28 w-28">
                        </div>
                    </div>
                </div>
            </div>
        )

    } else
        return (
            <div className="mx-auto  max-w-6xl lg:px-10  md:px-10 px-5  font-bodyFont">
                <Helmet>
                    <title>Guess The Game - Game Guess - Guess and Compete!</title>
                    <meta name="description" content=" Guess the Game - Game Guess. Enjoy the game guess gaming experience, and compete to be on the top player list! Guess the game from the pictures! The best game characters guess and game picture guess games are here!" />
                </Helmet>
                <VsAlertCard></VsAlertCard>
                <MiniGamePanel></MiniGamePanel>
                <div class="lg:flex justify-between shadow-sm shadow-black p-5 rounded-lg mt-10">
                    <div class="lg:w-1/3  lg:mb-0">
                        <p class="lg:text-4xl md:text-2xl sm:text-2xl text-2xl animate-pulse font-extrabold bg-clip-text bg-gradient-to-r text-transparent from-red-500 to-red-500 font-bodyFont text-center cursor-default duration-200 mb-2">Guess The Game</p>
                        <div class='flex justify-center gap-10 lg:mt-0 mt-4' >
                            <FaMobileScreen className='lg:w-14 lg:h-14 h-12 w-12 text-gray-300 hover:-rotate-12 duration-200 cursor-pointer'></FaMobileScreen>
                            <FaLaptop className='lg:w-14 lg:h-14 h-12 w-12 text-gray-300 hover:-translate-y-2 duration-200 cursor-pointer'></FaLaptop>
                            <FaTabletScreenButton className='lg:w-14 lg:h-14 h-12 w-12 text-gray-300 hover:rotate-12 duration-200 cursor-pointer'></FaTabletScreenButton>
                        </div>
                        <div class="mt-3 text-lg">
                            <div class="flex justify-center">
                                <a href="https://twitter.com/_gaguofficial" type="button" target="_blank" class="h-7 w-7 ml-2 rounded-full border-2 border-gray-300 uppercase leading-normal text-gray-300 hover:text-red-500 hover:border-red-500 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-full w-3" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                                    </svg>
                                </a>
                                <a href="https://www.instagram.com/_gaguofficial" target="_blank" type="button" class="h-7 w-7 ml-2 rounded-full border-2 border-gray-300 uppercase leading-normal text-gray-300 hover:text-red-500 hover:border-red-500 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-full w-3" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                    </svg>
                                </a>
                                <a href="https://www.linkedin.com/company/gameguesser" type="button" target="_blank" class="h-7 w-7 ml-2 rounded-full border-2 border-gray-300 uppercase leading-normal text-gray-300 hover:text-red-500 hover:border-red-500 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-full w-3" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                                    </svg>
                                </a>
                                <a href="https://www.tiktok.com/@game_guesser" type="button" target="_blank" class="h-7 w-7 ml-2 rounded-full border-2 border-gray-300 uppercase leading-normal text-gray-300 hover:text-red-500 hover:border-red-500 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0">
                                    <svg class="mx-auto h-full w-3" fill="currentColor" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" />
                                    </svg>
                                </a>

                            </div>
                        </div>
                    </div>
                    <div className='lg:mt-10  mt-5 flex justify-center'>
                        <Link to={"/rankedGames"}>
                            <button className='hover:rotate-2 active:bg-red-500 hover:scale-110 text-white text-xl px-2 ring-1 ring-red-500 py-3 rounded-lg duration-200'>Ranked Games!</button>
                        </Link>
                    </div>
                    <div class='lg:w-1/3 text-gray-300 lg:mt-0 mt-4 '>
                        <p class='flex align-bottom items-center mb-1.5 lg:text-base sm:text-sm text-xs  duration-200 hover:cursor-pointer hover:text-red-500'>
                            <FaImage className='w-6 h-6 mr-2 text-indigo-400 hover:-translate-y-1 duration-200'></FaImage>Guess the game from the picture.
                        </p>
                        <p class='flex  align-bottom items-center mb-1.5 lg:text-base sm:text-sm text-xs duration-200 hover:cursor-pointer hover:text-red-500'>
                            <FaUserLargeSlash className='w-6 h-6 mr-2 text-rose-400 hover:-translate-y-1 duration-200'></FaUserLargeSlash>No need to register to play.
                        </p>
                        <Link to={subId === null ? "/login" : "/createGame"} >
                            <p class='flex align-bottom items-center mb-1.5 lg:text-base sm:text-sm text-xs  duration-200 hover:cursor-pointer hover:text-red-500'>
                                <FaGamepad className='w-6 h-6 mr-2 text-green-400 hover:-translate-y-1 duration-200'></FaGamepad>Create your own game.
                            </p>
                        </Link>
                        <p class='flex align-bottom  items-center mb-1.5 lg:text-base sm:text-sm text-xs  duration-200 hover:cursor-pointer hover:text-red-500'>
                            <FaCrown className='w-6 h-6 mr-2 text-yellow-400 hover:-translate-y-1 duration-200'></FaCrown>Claim your spot on the leaderboard!
                        </p>
                        <p class='flex align-bottom items-center mb-1.5 lg:text-base sm:text-sm text-xs  duration-200 hover:cursor-pointer hover:text-red-500'>
                            <FaGem className='w-6 h-6 mr-2 text-sky-400 hover:-translate-y-1 duration-200'></FaGem>A new game is added every day.
                        </p>


                    </div>
                </div>
                <RankedGamePanel></RankedGamePanel>
                <TrendGamePanel></TrendGamePanel>
                {gameType.map((key, index) => (
                    <NormalGamePanel key={index} id={key.id} gameTypeName={key.gameTypeName}></NormalGamePanel>
                ))}
            </div>
        )
}

export default Home