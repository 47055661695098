import jwtDecode from 'jwt-decode'
import getAccessToken from './AccessToken';
// token.js

export const Token = async () => {

    try {
        const accessToken = await getAccessToken();
        if (accessToken) {
            const decodedToken = jwtDecode(accessToken);
            return {
                userRoles: decodedToken.roles,
                subId: decodedToken.sub,
                accessToken: accessToken
            };
        } else {
            return {
                userRoles: [],
                subId: null
            };
        }
    } catch (error) {
        console.error("Token alınamadı:", error);
        return {
            userRoles: [],
            subId: null
        };
    }

};



