import React from 'react'
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { Link } from 'react-router-dom'
import { getAvatarImageFromLocalStorage } from '../../../service/Avatar';

function RankedBigGameCard(props) {
    const { id, gameName, gameCreatorUserId, gameCreatorUserName, gameTypeName, gamePreviewImage, gameCreatorUserAvatarName, admin } = props;
    return (
        <div className="block shadow-lg mt-10 hover:scale-105 duration-200 cursor-pointer">
            <img
                alt="Guess The Game Image"
                src={`data:image/jpeg;base64,${gamePreviewImage}`}
                className=" w-full object-cover sm:h-64 lg:h-96 rounded-t-md"
            />
            <div className=' bg-zinc-800 rounded-b-md'>
                <div className="py-2 text-center ">
                    <a href="#" className="block lg:text-lg sm:text-sm  font-bold  text-white" tabIndex="0" role="link">{gameName}</a>
                </div>
                <div className="flex  items-center ">
                    <div className=' flex items-center my-2 mx-5'>
                        <img
                            className="h-12 w-12 rounded-full  ring-2 ring-gray-400"
                            src={`data:image/jpeg;base64,${getAvatarImageFromLocalStorage(gameCreatorUserAvatarName)}`}
                            alt="Game Creator Avatar"
                        />
                        <Link to={"/rankedGames"}>

                            <div href="#" className="text-center lg:text-lg sm:text-sm  font-bold  text-white hover:underline ml-3" tabIndex="0" role="link">
                                {gameCreatorUserName}
                                {admin === true ? <BsFillPatchCheckFill className="inline-block lg:text-xl  sm:text-base text-sm  text-green-500 ml-2" /> : ""}
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default RankedBigGameCard