import React from 'react'
import { Link } from 'react-router-dom'
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { getAvatarImageFromLocalStorage } from '../../../service/Avatar';

function NormalBigGameCard(props) {
    const { id, gameName, gameCreatorUserId, gameCreatorUserName, gameTypeName, gamePreviewImage, playerCount, gameCreatorUserAvatarName, isVerified } = props;
    return (
        <div className="block shadow-lg mt-10 hover:scale-105 duration-200 cursor-pointer">
            <img
                alt="Guess The Game Image"
                src={`data:image/jpeg;base64,${gamePreviewImage}`}
                className="h-74 w-full object-fill sm:h-80 lg:h-96 rounded-t-md "
            />
            <div className=' bg-zinc-800 rounded-b-md'>
                <div className="py-2 text-center">
                    <p href="#" className=" block lg:text-lg sm:text-sm  font-normal text-white " >{gameName}</p>
                </div>
                <div className="flex  items-center ">
                    <div className=' flex items-center my-2 mx-5'>
                        <img
                            className="h-12 w-12 rounded-full  ring-2 ring-gray-400"
                            src={`data:image/jpeg;base64,${getAvatarImageFromLocalStorage(gameCreatorUserAvatarName)}`}
                            alt="Game Creator Avatar"
                        />
                        <span className='flex justify-between'>
                            <Link to={"/userProfile/" + gameCreatorUserId}>
                                <div className="text-center lg:text-lg sm:text-sm  font-bold  text-white hover:underline ml-3" tabIndex="0" >
                                    {gameCreatorUserName}
                                    {isVerified === true ? <BsFillPatchCheckFill className="inline-block lg:text-xl  sm:text-base text-sm  text-indigo-500 ml-2" /> : ""}
                                </div>
                            </Link>
                        </span>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default NormalBigGameCard