import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import UserGameCard from '../card/UserGameCard';
import axios from 'axios';
import { FaCircleArrowDown } from 'react-icons/fa6'
import { BsFillPatchCheckFill } from "react-icons/bs";


import { formatDate } from '../../../service/FormatDate';
import UserAvatarPanel from '../card/UserAvatarPanel';
import { useTranslation } from 'react-i18next';
import UserLoadingGameCard from '../card/UserLoadingGameCard';
import { useToken } from '../../../service/context/TokenProvider';
import { FaRegEdit } from "react-icons/fa";
import UserUpdatePanel from '../card/UserUpdatePanel';
import { Helmet } from 'react-helmet';

function User() {
    const { userId } = useParams();
    const { t } = useTranslation();

    const [userInformation, setUserInformation] = useState()
    const { tokenData } = useToken();
    const { subId } = tokenData;

    const fillUserInformation = () => {
        axios.get('/user/userPageInformation/' + userId)
            .then((response) => {
                setUserInformation(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const [filledAllGames, setFilledAllGames] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading2, setIsLoading2] = useState(false)
    const [isLoading3, setIsLoading3] = useState(false)

    const [userGames, setUserGames] = useState([])
    const [searched, setSearched] = useState(false);
    const [searched2, setSearched2] = useState(false);

    const [userPopularGames, setUserPopularGames] = useState([])
    const [userTotalScore, setUserTotalScore] = useState(null)
    const [userLeaderboardCount, setUserLeaderboardCount] = useState(null)

    const fillUserPopularGames = () => {
        axios.get('/game/topTrendGamesByUserId/' + userId)
            .then((response) => {
                if (response.data.length === 0) {
                    setSearched(false); // Veri yok, "Değer bulunamadı" mesajını göster.
                    setIsLoading2(true);
                } else {
                    setSearched(true);
                    setIsLoading2(true); // Veri geldi, sonuçları göster.
                }
                setUserPopularGames(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const fillUserGames = () => {
        axios.get('/game/userpage/' + userId)
            .then((response) => {
                if (response.data.length === 0) {
                    setSearched2(false); // Veri yok, "Değer bulunamadı" mesajını göster.
                    setIsLoading3(true);
                } else {
                    setSearched2(true);
                    setIsLoading3(true); // Veri geldi, sonuçları göster.
                }
                setUserGames(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fillAllUserGames = () => {
        setIsLoading(true)
        axios.get('/game/user/' + userId)
            .then((response) => {
                setUserGames(response.data)
                setIsLoading(false)
                setFilledAllGames(true)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fillUserTotalScore = () => {
        axios.get('/rank/userTotalRank/' + userId)
            .then((response) => {
                setUserTotalScore(response.data)
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const fillUserLeaderBoardCount = () => {
        axios.get('/rank/userLeaderboard/' + userId)
            .then((response) => {
                setUserLeaderboardCount(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        fillUserInformation()
        fillUserPopularGames()
        fillUserGames()
        fillUserTotalScore()
        fillUserLeaderBoardCount()
    }, [userId])

    useEffect(() => {
        window.scrollTo(0, 0); // Sayfanın en üstüne scroll yap
    }, []);

    return (

        <div className="mx-auto max-w-7xl sm:px-20 lg:px-10  px-5 font-bodyFont">
            <Helmet>
                <title>Guess The Game - Game Guess - User Profile</title>
                <meta name="description" content="Game Guess - Guess the Game. Enjoy the gaming experience, and compete to be on the top player list! Guess the game from the pictures! The best game characters guess and game picture guess games are here!" />
            </Helmet>
            <div className="mx-auto max-w-7xl relative">
                <div className="mt-10 flex  items-center">
                    {subId === userId ? (<div>
                        {
                            userInformation ?
                                <div className="relative">
                                    <img
                                        className="mx-1 inline-block lg:h-44 lg:w-44 h-24 w-24 rounded-full ring-2 ring-zinc-500"
                                        src={`data:image/jpeg;base64,${userInformation.avatarImage}`}
                                        alt="Guess The Game User Avatar Image"
                                    />
                                    <div className=' text-center'>
                                        {subId === userId ? <UserAvatarPanel></UserAvatarPanel> : ""}
                                    </div>
                                </div>


                                :
                                <div className="mx-1 border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-4 lg:h-44 lg:w-44 h-24 w-24">
                                </div>
                        }
                    </div>) : (<div>
                        {
                            userInformation ?
                                <img
                                    className="mx-1 inline-block lg:h-44 lg:w-44 h-24 w-24 rounded-full ring-2 ring-zinc-500"
                                    src={`data:image/jpeg;base64,${userInformation.avatarImage}`}
                                />
                                :
                                <div className="mx-1 border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-4 lg:h-44 lg:w-44 h-24 w-24">
                                </div>
                        }
                    </div>)}

                    <div className="ml-3 mt-5">
                        <p className="text-white lg:text-3xl sm:text-lg">
                            {userInformation ? userInformation.user.userName : ""}
                            {userInformation ? userInformation.admin ? <BsFillPatchCheckFill className="inline-block lg:text-2xl lg:-mt-1 sm:text-sm sm:-mt-1 text-xs  text-green-500 ml-2" /> : "" : ""}
                            {userInformation ? userInformation.user.isVerified ? <BsFillPatchCheckFill className="inline-block lg:text-2xl lg:-mt-1 sm:text-sm sm:-mt-1 text-xs  text-indigo-500 ml-2" /> : "" : ""}
                        </p>
                        <p className="text-gray-600 lg:text-xl sm:text-sm ">
                            {userInformation ? formatDate(userInformation.user.createDate) : ""}
                        </p>
                        {subId === userId ? <UserUpdatePanel></UserUpdatePanel> : ""}

                    </div>
                </div>


                <div className='mb-1'>
                    {
                        userLeaderboardCount ?
                            <p className="flex   justify-end text-white lg:text-xl  sm:text-base text-sm ">
                                {"Top " + userLeaderboardCount + " on the list!"}
                            </p>
                            :
                            null
                    }
                    <hr className=" mt-1 border-gray-700 " />

                </div>

                <div className='flex justify-between gap-4  rounded-lg   '>
                    <div className='flex flex-col items-center my-5' style={{ width: '250px' }}>
                        <p className='lg:text-5xl md:text-xl text-base cursor-default duration-200 text-white font-extrabold  m-2'>
                            {userInformation ? userInformation.playedGameCount :
                                <div className='flex justify-center mt-10'>
                                    <div className="border-t-transparent border-solid animate-spin rounded-full border-red-500 border-8 h-6 w-6"></div>
                                </div>
                            }
                        </p>
                        <p className='text-gray-400 lg:text-base md:text-base text-xs m-2'>{t("userPage.text4")}</p>
                    </div>

                    <div className='flex flex-col items-center my-5' style={{ width: '250px' }}>
                        <p className={'lg:text-5xl md:text-xl text-lg cursor-default  font-extrabold bg-clip-text m-2 text-white'}>
                            {userTotalScore ? userTotalScore :
                                <div className='flex justify-center mt-10'>
                                    <div className="border-t-transparent border-solid animate-spin rounded-full border-red-500 border-8 h-6 w-6"></div>
                                </div>
                            }
                        </p>
                        <p className='text-gray-400 lg:text-base md:text-base text-xs m-2'>{t("userPage.text5")}</p>
                    </div>
                    <div className='flex flex-col items-center my-5' style={{ width: '250px' }}>
                        <p className='lg:text-5xl md:text-xl text-base cursor-default duration-200 text-white  m-2'>
                            {userInformation ? userInformation.createdGameCount :
                                <div className='flex justify-center mt-10'>
                                    <div className="border-t-transparent border-solid animate-spin rounded-full border-red-500 border-8 h-6 w-6"></div>
                                </div>
                            }
                        </p>
                        <p className='text-gray-400 lg:text-base md:text-base text-xs m-2'>{t("userPage.text6")}</p>
                    </div>
                </div>

                <div className='flex justify-between mt-20'>
                    <h1 className="lg:text-2xl sm:text-lg font-bold  text-white ">
                        {t("userPage.title1")}
                    </h1>
                </div>
                <div className='mx-1'>
                    <div className=' grid lg:grid-cols-3 mt-10 sm:grid-cols-1 gap-4'>
                        {!isLoading2 && (
                            <>
                                <UserLoadingGameCard></UserLoadingGameCard>
                                <UserLoadingGameCard></UserLoadingGameCard>
                                <UserLoadingGameCard></UserLoadingGameCard>
                            </>
                        )}
                        {!searched && isLoading2 && userPopularGames.length === 0 && (
                            <div className="flex justify-start">
                                <p className="text-red-500 lg:text-lg sm:text-sm">
                                    {t("userPage.text3")}
                                </p>
                            </div>
                        )}
                        {userPopularGames.map((game, index) => (
                            <Link to={"/gameView/" + game.id} key={index}>
                                <UserGameCard key={index} game={game}></UserGameCard>
                            </Link>
                        ))}
                    </div>

                </div>
                <div className='flex justify-between mt-20'>
                    <h1 className="lg:text-2xl sm:text-lg font-bold  text-white ">
                        {t("userPage.title2")}
                    </h1>
                </div>
                <div className='mx-1'>
                    <div className=' grid lg:grid-cols-3 mt-10 sm:grid-cols-1 gap-4'>
                        {!isLoading3 && (
                            <>
                                <UserLoadingGameCard></UserLoadingGameCard>
                                <UserLoadingGameCard></UserLoadingGameCard>
                                <UserLoadingGameCard></UserLoadingGameCard>
                            </>
                        )}
                        {!searched2 && isLoading3 && userGames.length === 0 && (
                            <div className="flex justify-start">
                                <p className="text-red-500  lg:text-lg sm:text-sm">
                                    {t("userPage.text3")}
                                </p>
                            </div>
                        )}
                        {
                            userGames.map((game, index) => (
                                <Link to={"/gameView/" + game.id} key={index}>
                                    <UserGameCard key={index} game={game}></UserGameCard>
                                </Link>
                            ))
                        }
                    </div>

                </div>
                {
                    userGames.length === 0
                        ? null // Oyun yoksa null döndür
                        :
                        filledAllGames && !isLoading ? // TODO: tum oyunlar getirirken spinner eklenebilir.
                            null
                            :
                            isLoading
                                ?
                                <div className='flex justify-center mt-10'>
                                    <div className="border-t-transparent border-solid animate-spin rounded-full border-red-500 border-8 h-20 w-20"></div>
                                </div>
                                :
                                <div className='flex justify-center items-center mt-10 '>
                                    <FaCircleArrowDown className=' animate-bounce lg:h-14 lg:w-14 h-10 w-10 text-red-500 cursor-pointer' onClick={fillAllUserGames}></FaCircleArrowDown>
                                </div>
                }

            </div>

        </div>
    )

}

export default User