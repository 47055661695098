import React, { useState } from 'react'
import { GiHornedHelm } from 'react-icons/gi'
import { FiXCircle } from "react-icons/fi";

function VsAlertCard() {
    const [showAlert, setShowAlert] = useState(true);

    const closeAlert = () => {
        localStorage.setItem('showAlertControl', 'false');
        setShowAlert(false);
    };
    return (
        <div className='sticky top-24 z-50 mt-2'>
            {!localStorage.getItem('showAlertControl') ? (
                <div role="alert" className="rounded-xl border border-red-500 mx-auto bg-zinc-800 md:px-10 px-5 p-4 relative"> 
                    <div className="flex items-center justify-between">
                        <GiHornedHelm className="lg:w-16 lg:h-16 md:w-16 md:h-16 sm:w-16 sm:h-16 w-10 h-10 text-red-500  justify-center hidden md:block" />
                        <div className="flex-1 text-white ml-4">
                            <strong className="block font-bold text-xl">Create your own custom game!</strong>
                            <p className="mt-1 text-base">You can create your own custom game and play with your friends.</p>
                        </div>
                       
                        <div className="absolute top-0 right-0 mt-2 mr-2"> {/* Added absolute and positioning classes */}
                            <FiXCircle className="text-white w-7 h-7 hover:text-gray-400 cursor-pointer" onClick={closeAlert} />
                        </div>
                    </div>
                </div>


            )
                : null}

        </div>
    )
}

export default VsAlertCard