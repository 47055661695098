import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ranked from '../../../assets/ranked.gif';
import RankedGame from '../card/RankedGame';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Ranked() {
    const [gameType, setGameType] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const { t } = useTranslation();

    const getAllGameTypePanel = async () => {
        await axios.get('/gameType/getAll')
            .then((response) => {
                setGameType(response.data)
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }

    useEffect(() => {
        getAllGameTypePanel()
    }, [])

  

    return (

        <div className="mx-auto max-w-7xl sm:px-20 lg:px-10  px-5  font-bodyFont"  >
            <Helmet>
                <title>Guess The Game - Game Guess - Ranked Games </title>
                <meta name="description" content="Game Guess - Guess the Game. Guess the game, enjoy the gaming experience, and compete to be on the top player list! Find exclusive and exciting ranked games in this category. Guess the game from the pictures! The best game characters and game picture guess games are here!" />
            </Helmet>
            <div className="mx-auto max-w-7xl relative">
                <img
                    alt="Guess The Game Preview"
                    src={ranked}
                    className="bg-cover w-full h-64 shadow-sm rounded-b-lg bg-gray-800"
                />
                <h1 className="flex sm:px-20 lg:px-16 lg:text-3xl  sm:text-lg text-lg font-bold  justify-center text-white my-10 ">
                    {t('rankedGamePage.title1')}
                </h1>
            </div>
            <div className='mx-1'>
                {gameType.map((key, index) => (
                    <Link to={"/gameView/" + key.id} key={index}>
                        <RankedGame key={index} id={key.id} gameTypeName={key.gameTypeName}></RankedGame>
                    </Link>
                ))}

            </div>
        </div>


    )
}

export default Ranked