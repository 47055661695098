import { useEffect, useRef, useState } from 'react'
import { Disclosure, Menu } from '@headlessui/react'
import Translate from '../../service/Translate'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { getAvatarImageFromLocalStorage } from '../../service/Avatar'
import { useTranslation } from "react-i18next"
import { useToken } from '../../service/context/TokenProvider'
import logo from '../../assets/logos.png'



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}



export default function Navbar() {
    const [error, setError] = useState(null);
    const [isLoadedGame, setIsLoadedGame] = useState(false);
    const [isGames, setIsGames] = useState([]);
    const [searhGameName, setSearhGameName] = useState([]);
    const [isSearching, setIsSearching] = useState(false)
    const [debouncedValue, setDebouncedValue] = useState('');
    const { t } = useTranslation();

    const inputRef = useRef(null);
    const viewGameRef = useRef(null);

    var navigate = useNavigate();
    const { tokenData, resetTokenData } = useToken();
    const { subId } = tokenData;

    const navigation = [
        { name: t('mainPage.navbar.title1'), to: '/', current: false },
        { name: t('mainPage.navbar.title2'), to: '/rankedGames', current: false },
        { name: t('mainPage.navbar.title3'), to: '/topListPlayer', current: false },
    ]


    const onLogoutClicked = () => {
        localStorage.removeItem("signedRefreshToken");
        localStorage.removeItem("signedUserAvatarName");
        localStorage.removeItem("signedUserId");
        navigate("/");
        resetTokenData();
        //window.location.reload();
    };

    const getSearchGameName = () => {
        axios.get("/game/searchGameByName?gameName=" + searhGameName)
            .then(function (response) {
                return response.data;
            })
            .then(
                async (result) => {
                    setIsLoadedGame(true);
                    setIsGames(result);
                },
                (error) => {
                    setIsLoadedGame(true);
                    setError(error);
                }
            );
    };
    useEffect(() => {
        function handleClickOutside(event) {
            if (inputRef.current && !inputRef.current.contains(event.target) && !viewGameRef.current?.contains(event.target)) {
                setIsGames([]);
                setSearhGameName('');
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        let timeoutId;

        if (searhGameName.length >= 3) {
            // Kullanıcı yazmayı tamamladıktan 1 saniye sonra arama yapılacak
            timeoutId = setTimeout(() => {
                getSearchGameName();

            }, 1000);

            setIsSearching(true);
        }
        if (isGames.length === 0) {
            setIsLoadedGame(false);
            setIsSearching(false);
        }
        return () => {
            // useEffect içinde setTimeout kullanıyorsak, bileşen yeniden render edildiğinde
            // bu setTimeout'leri temizlememiz önemlidir. Bu, bellek sızıntılarını engellemeye yardımcı olur.
            clearTimeout(timeoutId);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searhGameName]);



    const handleSearch = (event) => {
        event.preventDefault();
        setIsSearching(true);
        getSearchGameName();
    };

    const handleInputChange = async (event) => {
        const value = event.target.value;
        setSearhGameName(value);
        // Eğer input boşaltıldıysa, isUsers state'ini de boş bir liste olarak ayarla
        if (value.length < 3) {
            setIsGames([]);
        }
    };
    //useProfile giderken temizle
    const handleLinkClick = () => {
        setIsGames([]);
        setSearhGameName('');
    };


    return (
        <Disclosure as="nav" className="bg-zinc-900 sticky top-0 z-50 w-full ring-1 ring-gray-500 font-bodyFont">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-6xl px-4 py-3">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {/* Hamburger icon */}
                                    {open ? (
                                        <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    ) : (
                                        <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                        </svg>
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex items-center justify-center">
                                <div className="">
                                    <img
                                        onClick={() => navigate('/')}
                                        src={logo}
                                        className="lg:h-14 md:h-10 sm:h-8 h-8 lg:ml-0 md:ml-0 ml-16 flex cursor-pointer"
                                        alt="Your Company"
                                    />
                                </div>
                                <div className="hidden sm:flex m-auto text-sm lg:text-xl lg:flex md:flex items-center justify-center">
                                    <div className="flex ml-10 gap-6">
                                        {navigation.map((item, index) => (
                                            <Link
                                                key={index}
                                                to={item.to}
                                                onClick={() => {
                                                    window.scroll({
                                                        top: 0,
                                                        left: 0,
                                                        behavior: 'smooth',
                                                    });
                                                }}
                                            >
                                                <button
                                                    key={item.name}
                                                    className={classNames('text-gray-300 hover:text-red-500 duration-200 rounded-md  font-medium')}
                                                    aria-current={item.current ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </button>
                                            </Link>
                                        ))}
                                        <Link
                                            to="https://animeguess.app"
                                            target="_blank"
                                            onClick={() => {
                                                window.scroll({
                                                    top: 0,
                                                    left: 0,
                                                    behavior: 'smooth',
                                                });
                                            }}
                                        >
                                            <button
                                                className={classNames('text-gray-300 hover:text-red-500 duration-200 rounded-md font-medium')}
                                            >
                                                Anime Guess
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                {/* User Avatar */}
                                <div className="flex-shrink-0">
                                    {/* Your avatar component or image */}
                                </div>
                            </div>



                            <div className=" flex items-center sm:static ">
                                <Translate></Translate>

                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-5">

                                    {subId === null ?
                                        (
                                            <Link to="/login">
                                                <button className="flex lg:text-md  items-center px-4 py-2 font-medium tracking-wide text-white transition-colors duration-200 transform   hover:border-white  bg-red-500  rounded-lg hover:bg-red-400 focus:outline-none focus:ring ">
                                                    <span className="mx-1"> {t('mainPage.navbar.buttonText')}</span>
                                                </button>
                                            </Link>

                                        )
                                        : (
                                            <Menu.Button
                                                className="relative flex rounded-full bg-slate-950 text-sm focus:outline-none ">
                                                <span className="absolute -inset-1.5" />
                                                <span className="sr-only">Open user menu</span>
                                                <img
                                                    className="lg:h-16 lg:w-16 sm:h-16 sm:w-16 h-14 w-14 rounded-full ring-2 ring-gray-400"
                                                    src={`data:image/jpeg;base64,${getAvatarImageFromLocalStorage(localStorage.getItem("signedUserAvatarName"))}`}
                                                    alt="Guess The Game User Avatar Image"
                                                />
                                            </Menu.Button>
                                        )}

                                    {/* Dropdown menu */}
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-zinc-900 py-1 shadow-md shadow-slate-800  ring-1 ring-zinc-600">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to={"/userProfile/" + localStorage.getItem("signedUserId")}>
                                                    <button
                                                        href="#"
                                                        className={classNames(active ? 'bg-zinc-800  ' : '', 'flex items-start  w-full px-4 py-2 text-sm text-white ')}
                                                    >
                                                        {t('mainPage.navbar.text1')}
                                                    </button>
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to={"/createGame"}>
                                                    <button
                                                        href="#"
                                                        className={classNames(active ? 'bg-zinc-800 ' : '', 'flex items-start  w-full px-4 py-2 text-sm text-white ')}
                                                    >
                                                        {t('mainPage.navbar.text4')}
                                                    </button>
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    onClick={onLogoutClicked}

                                                    className={classNames(active ? 'bg-zinc-800' : '', 'flex items-start  w-full px-4 py-2 text-sm text-white')}
                                                >
                                                    {t('mainPage.navbar.text2')}
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Menu>

                            </div>
                        </div>

                    </div>

                    {/* Mobile menu */}
                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            {navigation.map((item, index) => (

                                <Link
                                    key={index}
                                    to={item.to}
                                    onClick={() => {
                                        window.scroll({
                                            top: 0,
                                            left: 0,
                                            behavior: 'smooth',
                                        });
                                    }}
                                >
                                    <Disclosure.Button
                                        key={item.name}
                                        as="a"
                                        className={classNames(
                                            item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'block rounded-md px-3 py-2 text-base font-medium'
                                        )}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </Disclosure.Button>

                                </Link>
                            ))}
                            <Link
                                to={"https://gameguesser.xyz"}
                                target="_blank"
                                onClick={() => {
                                    window.scroll({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth',
                                    });
                                }
                                }
                            >
                                <button
                                    className={classNames('text-gray-300 hover:text-indigo-500 duration-200 rounded-md px-3 py-2 font-medium ')}
                                >
                                    Game Guesser
                                </button>
                            </Link>
                        </div>
                    </Disclosure.Panel>
                </>
            )
            }
        </Disclosure >
    )

}
