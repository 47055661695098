import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FaCircleInfo } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useToken } from '../../service/context/TokenProvider';
import SessionStatus from '../../service/SessionStatus';
import AdminViewUser from '../view/AdminViewUser';

function AdminUserControl() {
    const [pageableUser, setPageableUser] = useState([])
    const { tokenData } = useToken();
    const { accessToken } = tokenData
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [session, setSession] = useState(false);
    const { t } = useTranslation();
    const [isLoadedUser, setIsLoadedUser] = useState(false);
    const [isUser, setIsUser] = useState([]);
    const [searchUserName, setSearchUserName] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate()
    const [checkKey, setCheckKey] = useState(false)



    const getSearchUserName = () => {
        axios.get("/user/searchUserByName?userName=" + searchUserName, {
            headers: {
                Authorization: accessToken
            }
        })
            .then(function (response) {
                return response.data;
            })
            .then(
                async (result) => {
                    setIsLoadedUser(true);
                    setIsUser(result);
                },
                (error) => {
                    setIsLoadedUser(true);
                    setError(error);
                }
            );
    };

    const getAllUserPageable = async (page) => {
        try {
            const response = await axios.get(`/user/getAllUserPageable?page=${page}&size=25`, {
                headers: {
                    Authorization: accessToken
                }
            });
            setPageableUser(response.data);
            setIsLoaded(true);
        } catch (error) {
            if (error.response.data.message === "Access Denied") {
                setIsLoaded(true);
                setSession(t("session.text1"));
                return
            }


        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.post("/param/getPanelLink/send?paramValue=" + localStorage.getItem('key'), {}, {
                    headers: {
                        Authorization: accessToken,
                    },
                });
                if (response.status === 200) {
                    setCheckKey(true);
                    getAllUserPageable(currentPage); // Sayfa yüklendiğinde ilk çağrıyı yap
                } else if (response.status === 404) {
                    localStorage.removeItem('key');
                    navigate("/forbidden");
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setCheckKey(false);
                    localStorage.removeItem('key');
                    navigate("/forbidden");
                }
                console.error("Bir hata oluştu: ", error);
            }
        }
        fetchData();
    }, [localStorage.getItem('key')]);


    useEffect(() => {
        getAllUserPageable(currentPage); // Sayfa yüklendiğinde ilk çağrıyı yap
    }, [currentPage])


    useEffect(() => {
        if (searchUserName.length >= 3) {
            getSearchUserName();
            setIsSearching(true);
        }
        if (isUser.length === 0) {

            setIsSearching(false);
        }

    }, [searchUserName, currentPage]);



    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber); // Sayfa numarasını güncelle
    };
    const handlePrevPageClick = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPageClick = () => {
        if (currentPage < pageableUser.totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleInputChange = async (event) => {
        const value = event.target.value;
        setSearchUserName(value);
        // Eğer input boşaltıldıysa, isUsers state'ini de boş bir liste olarak ayarla
        if (value.length < 3) {
            setIsUser([]);
        }
    };




    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen flex-col">
                <div className="text-4xl font-bold text-red-500 mb-4">HATA!</div>
                <div className="text-2xl font-bold text-red-500">HATA!</div>
            </div>
        )

    } 
    else if (!checkKey) {
        return (
            <div className='fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black  z-50'>
                <div className='text-white border-zinc-600 rounded-lg'>
                    <div className='flex justify-center items-center mt-20'>
                        <div className='border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-8 h-28 w-28'></div>
                    </div>
                </div>
            </div>
        );
    }
    else if (!isLoaded) {
        return (
            <div className='text-white border-zinc-600 rounded-lg'>
                <div className="flex  justify-center items-center mt-20 prounded-lg  ">
                    <div className="">
                        <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-8 h-28 w-28">
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="flex flex-col font-bodyFont text-white">
                {session && (
                    <SessionStatus session={session}></SessionStatus>
                )}
                <div className=" mx-auto sm:mx-40">
                    <h2 className="text-2xl font-semibold my-2 ml-2"> Kullanıcılar</h2>
                    <form>
                        <div className="flex flex-col-reverse md:flex-row items-stretch">
                            <input
                                type="search"
                                className="relative m-0 text-lg -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-md border border-solid  bg-transparent bg-clip-padding px-3 py-[0.25rem] font-normal leading-[1.6] text-white outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-white-700 focus:outline-none border-neutral-600 text-white-200 placeholder:text-white-200 focus:border-primary"
                                placeholder="Adına Göre Ara"
                                aria-label="Search"
                                aria-describedby="button-addon1"
                                value={searchUserName}
                                onChange={handleInputChange}
                            />
                            <button
                                className="relative mr-2 z-[2] border-t border-r border-b border-neutral-600 flex items-center rounded-md bg-primary px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white transition duration-150 ease-in-out hover:bg-primary-700 focus:outline-none focus:ring-0 active:bg-primary-800"
                                type="submit"
                                id="button-addon1"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-7 w-7"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                    </form>
                    <div className="relative z-50">
                        <div className="absolute top-0 left-0 w-full">
                            {isUser.length > 0 && isLoadedUser ? (
                                <div className="border border-solid bg-zinc-900 border-zinc-600 rounded-lg lg:text-lg sm:text-sm">
                                    {isUser.map((key, index) => (
                                        <div className="flex justify-between py-1 border-zinc-600 cursor-pointer hover:bg-zinc-800">
                                            <AdminViewUser key={index} id={key.userId} userName={key.userName} userMail={key.userMail} >
                                                <div className="font-medium text-sm px-1">{key.userName}</div>
                                            </AdminViewUser>
                                        </div>

                                    ))}
                                </div>
                            ) : (
                                searchUserName.length > 2 && !isLoadedUser && !isSearching ? (
                                    <div className="text-white border border-solid border-zinc-600 rounded-lg">
                                        <div className="flex justify-center py-1 rounded-lg my-1">
                                            <div className="">
                                                <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-4 h-12 w-12"></div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    searchUserName.length > 2 && (
                                        <div className="border border-solid bg-zinc-900 border-zinc-600 rounded-lg lg:text-lg sm:text-sm">
                                            <div className="flex justify-center py-1 my-1">
                                                <div className="font-medium">Sonuç bulunamadı.</div>
                                            </div>
                                        </div>
                                    )
                                )
                            )}
                        </div>
                    </div>

                </div>
                <div className="overflow-x-auto  ">
                    <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden">
                            <div className="inline-block min-w-full overflow-x-auto">

                                {pageableUser ? (
                                    pageableUser.length === 0 ?
                                        (
                                            <p className="text-xl  mt-1 text-start align-middle ">Kullanıcı bulunamadi.</p>

                                        ) : (
                                            <table className="min-w-full text-left text-sm font-light">
                                                <thead className="border-b font-medium border-neutral-500">
                                                    <tr>
                                                        <th scope="col" className="px-4 py-2 text-blue-500 text-lg">*</th>
                                                        <th scope="col" className="px-4 py-2 text-blue-500 text-lg">id</th>
                                                        <th scope="col" className="px-4 py-2 text-blue-500 text-lg">Nick</th>
                                                        <th scope="col" className="px-4 py-2 text-blue-500 text-lg">E-Mail</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pageableUser ? pageableUser.content.map((key, index) => (
                                                        <tr
                                                            className="border-b transition duration-300 ease-in-out  border-neutral-500 hover:bg-neutral-600">
                                                            <td className="whitespace-nowrap px-4 py-1 font-medium">{index + 1} </td>
                                                            <td className="whitespace-nowrap px-4 py-1 font-medium">{key.id} </td>

                                                            <td className="whitespace-nowrap px-4 py-1">{key.userName} </td>
                                                            <td className="whitespace-nowrap px-4 py-1">{key.userMail}</td>
                                                            <td className="whitespace-nowrap px-4 py-1">
                                                                <span className="flex justify-between">
                                                                    <AdminViewUser key={index} id={key.id} avatar={key.avatar.id} updateDate={key.updateDate} roles={key.roles} userName={key.userName} createDate={key.createDate} userMail={key.userMail} isVerified={key.isVerified}>
                                                                        <FaCircleInfo className='text-blue-400 text-lg cursor-pointer hover:text-blue-500 duration-200'></FaCircleInfo>
                                                                    </AdminViewUser>

                                                                </span>
                                                            </td>

                                                        </tr>
                                                    )) : null}


                                                </tbody>
                                            </table>
                                        )

                                ) : (
                                    null
                                )}


                            </div>
                            <div className="flex justify-center">
                                <a
                                    href="#"
                                    onClick={handlePrevPageClick}
                                    className="flex items-center justify-center px-2 py-2 mx-1 transition-colors duration-300 transform bg-gray-800 text-white rounded-md rtl:-scale-x-100  "
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </a>

                                {Array.from({ length: pageableUser.totalPages }, (_, i) => (
                                    <a
                                        onClick={() => handlePageClick(i)}
                                        className={`px-2 py-2 mx-1 cursor-pointer transition-colors duration-300 transform rounded-md sm:inline text-gray-200  ${currentPage === i ? 'font-bold bg-red-500 text-white' : ''}`}
                                    >
                                        {i + 1}
                                    </a>
                                ))}

                                <a
                                    href="#"
                                    onClick={handleNextPageClick}
                                    className="flex items-center justify-center px-2 py-2 mx-1 transition-colors duration-300 transform bg-gray-800 text-white rounded-md rtl:-scale-x-100  "
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminUserControl