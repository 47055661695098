import React from 'react'

function LoadingMiniCard() {
    return (
        <div className="flex flex-col items-center  justify-center w-full max-w-sm mx-auto hover:-translate-y-3 duration-200 cursor-pointer   rounded-lg  ">
            <div
                className="lg:w-28  lg:h-28 sm:w-20 sm:h-20  bg-zinc-700  bg-center bg-cover  animate-pulse rounded-lg shadow-md  ring-2 ring-zinc-500" ></div>
            <div className=" -mt-5 px-2  bg-zinc-800  rounded-lg animate-pulse  shadow-lg w-32 ">
                <div className="lg:py-4 sm:py-3 font-bold lg:text-md sm:text-sm tracking-wide rounded text-center animate-pulse text-white  "></div>
            </div>
        </div>
    )
}

export default LoadingMiniCard