import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import LoadingMiniCard from './LoadingMiniCard';

function MiniGameCard(props) {
    const { id, gameTypeName } = props
    const { t } = useTranslation();
    const [image, setImage] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const loadImage = async () => {
            try {
                const res = await import(`../../../assets/${id}.gif`);
                setImage(res.default);
                setIsLoaded(true);
            } catch (error) {
                console.error('Dosya içe aktarımı sırasında bir hata oluştu: ', error);
            }
        };
        loadImage();
    }, [id]);


    const style = {
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    };

    return (
        <div className=''>
            {image === null && !isLoaded ?
                <>
                    <LoadingMiniCard></LoadingMiniCard>
                </>
                : <Link to={"/gameCategory/" + id}
                >
                    <div className="flex flex-col items-center  justify-center w-full max-w-sm mx-auto hover:-translate-y-3 duration-200 cursor-pointer ">
                        <div
                            style={style}
                            className="lg:w-28  lg:h-28 sm:w-20 sm:h-20 h-12 w-12 bg-center bg-cover rounded-lg shadow-md  ring-2 ring-zinc-500" ></div>
                        <div className=" lg:-mt-5 md:-mt-5 sm:-mt-5 px-2 bg-zinc-800 rounded-lg shadow-lg lg:w-32 w-20 -mt-2">
                            <h3 className="lg:py-2 sm:py-1 lg:font-semibold  lg:text-sm md:text-sm sm:text-xs  text-xs tracking-wide text-center text-white  ">  {t('mainPage.gamePanel.' + gameTypeName)} </h3>
                        </div>
                    </div>
                </Link>}

        </div>
    )
}

export default MiniGameCard