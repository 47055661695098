import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Outlet } from "react-router-dom";
import CreateGame from "./components/game/page/CreateGame";
import EndGame from "./components/game/page/EndGame";
import Game from "./components/game/page/Game";
import GameCategory from "./components/game/page/GameCategory";
import Home from "./components/home/page/Home";
import Footer from "./components/layouts/Footer";
import Navbar from "./components/layouts/Navbar";
import Login from "./components/login/Login";
import Error from "./pages/Error";
import Maintenance from "./pages/Maintenance";

import Register from "./components/login/Register";
import Ranked from "./components/ranked/page/Ranked";
import User from "./components/user/page/User";
import ForgotPassword from "./components/login/ForgotPassword";
import SideBar from "./admin/layouts/SideBar";
import Forbiden from "./pages/Forbiden";
import AdminPanel from "./admin/page/AdminPanel";
import AdminStatistics from "./admin/page/AdminStatistics";
import AdminUserControl from "./admin/page/AdminUserControl";
import AdminGameControl from "./admin/page/AdminGameControl";
import AdminReport from "./admin/page/AdminReport";
import AdminControlManager from "./admin/page/AdminControlManager";
import ResetPassword from "./components/login/ResetPassword";
import { useToken } from "./service/context/TokenProvider";
import About from "./components/layouts/About";
import Contact from "./components/layouts/Contact";
import PrivacyPolicy from "./components/layouts/policy/PrivacyPolicy";
import Cookie from "./components/layouts/policy/Cookie";
import TermsOfUse from "./components/layouts/policy/TermsOfUse";
import AdminBannedAccount from "./admin/page/AdminBannedAccount";
import HowToPlay from "./components/layouts/HowToPlay";
import GameView from "./components/game/card/GameView";
import TopList from "./components/topList/page/TopList";

function App() {
  const { tokenData } = useToken();
  const { userRoles, subId } = tokenData


  const WithNavbar = () => (
    <>
      <Navbar></Navbar>
      <Outlet></Outlet>
      <Footer></Footer>

    </>
  );

  const WithSideBar = () =>
  (
    userRoles.includes("GxGx_S23SC") == true && subId != null ?
      <div className="flex flex-row">
        <div className=" ">
          <SideBar></SideBar>
        </div>
        <div className="grow">
          <Outlet />
        </div>

      </div>
      :
      <Forbiden></Forbiden>
  )

  return (
    <div className='min-h-screen font-bodyFont  bg-zinc-900'>
      <Router>
        <Routes>

          <Route element={<WithNavbar></WithNavbar>}>
            <Route exact path="/" element={<Home></Home>}></Route>
            <Route exact path="/userProfile/:userId" element={<User></User>}></Route>
            <Route exact path="/gameCategory/:gameCategoryId" element={<GameCategory></GameCategory>}></Route>
            <Route exact path="/gameView/:gameId" element={<GameView></GameView>}></Route>
            <Route exact path="/topListPlayer" element={<TopList></TopList>}></Route>


            <Route exact path="/about" element={<About></About>}></Route>
            <Route exact path="/contact" element={<Contact></Contact>}></Route>
            <Route exact path="/howtoplay" element={<HowToPlay></HowToPlay>}></Route>

            <Route exact path="/privacypolicy" element={<PrivacyPolicy></PrivacyPolicy>}></Route>
            <Route exact path="/cookie" element={<Cookie></Cookie>}></Route>
            <Route exact path="/termsofuse" element={<TermsOfUse></TermsOfUse>}></Route>

            <Route exact path="/rankedGames" element={<Ranked></Ranked>}></Route>
            <Route exact path="/createGame" element={<CreateGame></CreateGame>}></Route>
          </Route>

          <Route element={<WithSideBar></WithSideBar>}>
            <Route exact path="/S5mQ1dP8kR3zN7fH2yL6" element={<AdminPanel></AdminPanel>}></Route>
            <Route exact path="/4a6R9sP2Lq8xN7dF3jK1" element={<AdminStatistics></AdminStatistics>}></Route>
            <Route exact path="/D7rP2f9JkT6sH1lN8wQ0" element={<AdminUserControl></AdminUserControl>}></Route>
            <Route exact path="/G5sR2tP8mQ1dN7fH3lK6" element={<AdminGameControl></AdminGameControl>}></Route>
            <Route exact path="/L9zR3bF7pW2kT6yH1mQ0" element={<AdminReport></AdminReport>}></Route>
            <Route exact path="/M4sN8qR2fT6hJ9pL3kY1" element={<AdminBannedAccount></AdminBannedAccount>}></Route>
            <Route exact path="/K2yR8sH3fP7mL1qN9tA6" element={<AdminControlManager></AdminControlManager>}></Route>
          </Route>

          <Route exact path="/login" element={<Login></Login>}></Route>
          <Route exact path="/forgotPassword" element={<ForgotPassword></ForgotPassword>}></Route>
          <Route exact path="/resetPassword" element={<ResetPassword></ResetPassword>}></Route>
          <Route exact path="/game/:gameId" element={<Game></Game>}></Route>

          <Route exact path="/endGame/:gameId" element={<EndGame></EndGame>}></Route>

          <Route exact path="/join" element={<Register></Register>}></Route>
          <Route exact path="/mainteanance" element={<Maintenance></Maintenance>}></Route>
          <Route exact path="/forbidden" element={<Forbiden></Forbiden>}></Route>

          <Route path="*" element={<Error></Error>}></Route>

        </Routes>

      </Router>
    </div>
  );
}

export default App;
