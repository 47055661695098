import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useToken } from '../../service/context/TokenProvider';

function AdminStatistics() {
  const [userByCount, setUserByCount] = useState([]);
  const [guestUserCount, setGuestUserCount] = useState([]);
  const [playedByCount, setPlayedByCount] = useState([]);
  const [guestPlayedByCount, setGuestPlayedByCount] = useState([]);
  const [gameByCount, setGameByCount] = useState([]);
  const [reportByCount, setReportByCount] = useState([]);
  const [adminCount, setAdminCount] = useState([]);
  const [checkKey, setCheckKey] = useState(false)
  const { tokenData } = useToken();
  const { accessToken } = tokenData
  const navigate = useNavigate()

  const getAllUserCount = () => {
    axios
      .get("/user/getAllUserCount")
      .then(function (response) {
        return response.data;
      })
      .then(
        (result) => {
          setUserByCount(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const getAllGameCount = () => {
    axios
      .get("/game/getAllGameCount")
      .then(function (response) {
        return response.data;
      })
      .then(
        (result) => {
          setGameByCount(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const getAllReportCount = () => {
    axios
      .get("/message/getAllReportCount")
      .then(function (response) {
        return response.data;
      })
      .then(
        (result) => {
          setReportByCount(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const getAllPlayedCount = () => {
    axios
      .get("/played/getAllPlayedCount")
      .then(function (response) {
        return response.data;
      })
      .then(
        (result) => {
          setPlayedByCount(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const getAllGuestPlayedCount = () => {
    axios
      .get("/guestplayed/getAllPlayedCount")
      .then(function (response) {
        return response.data;
      })
      .then(
        (result) => {
          setGuestPlayedByCount(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const getAllAdminCount = () => {
    axios
      .get("/user/getAllAdminCount")
      .then(function (response) {
        return response.data;
      })
      .then(
        (result) => {
          setAdminCount(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const getAllGuestUserCount = () => {
    axios
      .get("/guestplayed/getAllGuestUserCount")
      .then(function (response) {
        return response.data;
      })
      .then(
        (result) => {
          setGuestUserCount(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.post("/param/getPanelLink/send?paramValue=" + localStorage.getItem('key'), {}, {
          headers: {
            Authorization: accessToken,
          },
        });
        if (response.status === 200) {
          setCheckKey(true);
          getAllUserCount();
          getAllGuestUserCount()
          getAllGameCount();
          getAllReportCount();
          getAllPlayedCount();
          getAllGuestPlayedCount();
          getAllAdminCount()
        } else if (response.status === 404) {
          localStorage.removeItem('key');
          navigate("/forbidden");
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setCheckKey(false);
          localStorage.removeItem('key');
          navigate("/forbidden");
        }
        console.error("Bir hata oluştu: ", error);
      }
    }
    fetchData();
  }, [localStorage.getItem('key')]);


  if (!checkKey) {
    return (
      <div className='fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black  z-50'>
        <div className='text-white border-zinc-600 rounded-lg'>
          <div className='flex justify-center items-center mt-20'>
            <div className='border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-8 h-28 w-28'></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className=" rounded-md font-bodyFont text-white">
        <h2 className="text-2xl font-semibold  my-5 ml-2"> İstatistikler</h2>
        <h3 className="ml-10 text-xl font-semibold p-2 my-4">Toplam</h3>

        <ul className="">
          <li className="flex justify-between bg-zinc-800  w-full p-2">
            <span className='ml-10 text-2xl'> Admin Sayısı:</span>
            <span className='text-3xl text-blue-500 mr-10'>{adminCount} </span>
          </li>

          <li className="flex justify-between  w-full p-2">
            <span className='ml-10 text-2xl'> Kullanıcı Sayısı:</span>
            <span className='text-3xl text-blue-500 mr-10'>{userByCount} </span>
          </li>

          <li className="flex justify-between  bg-zinc-800 border-gray-300 p-2">
            <span className=' ml-10 text-2xl'> Misafir Kullanıcı Sayısı:</span>
            <span className='text-3xl text-blue-500 mr-10'>{guestUserCount} </span>
          </li>

          <li className="flex justify-between  border-gray-300 p-2">
            <span className=' ml-10 text-2xl'> Oyun Sayısı:</span>
            <span className='text-3xl text-blue-500 mr-10'>{gameByCount} </span>
          </li>

          <li className="flex justify-between bg-zinc-800 w-full p-2">
            <span className='ml-10 text-2xl'> Oynanan Oyun Sayısı:</span>
            <span className='text-3xl text-blue-500 mr-10'>{playedByCount} </span>
          </li>

          <li className="flex justify-between w-full p-2">
            <span className='ml-10 text-2xl'> Misafir Oynanan Oyun Sayısı:</span>
            <span className='text-3xl text-blue-500 mr-10'>{guestPlayedByCount} </span>
          </li>

          <li className="flex justify-between bg-zinc-800 w-full p-2">
            <span className='ml-10 text-2xl'> Şikayet Sayısı:</span>
            <span className='text-3xl text-blue-500 mr-10'>{reportByCount} </span>
          </li>

        </ul>
      </div>


    )
  }

}

export default AdminStatistics