
function UserGameCard(props) {
    const { game } = props

    return (
        <div href="#" className="block hover:scale-105 duration-200 cursor-pointer">
            <img
                alt="Guess The Game Image"
                src={`data:image/jpeg;base64,${game.gamePreviewImage}`}
                className="h-64 w-full object-cover sm:h-40 lg:h-44 rounded-t-md"
            />
            <div className=' bg-zinc-800 rounded-b-md'>
                <div className="py-4 text-center ">
                    <a href="#" className="block lg:text-lg sm:text-sm  font-bold   text-white" tabIndex="0" role="link">{game.gameName}</a>
                </div>
            </div>
        </div>
    )
}

export default UserGameCard