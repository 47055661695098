import axios from 'axios';
import React, { useEffect, useState } from 'react'
import MiniGameCard from './MiniGameCard'
import LoadingMiniCard from './LoadingMiniCard';

function MiniGamePanel() {

    const [gameType, setGameType] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const getAllGameTypePanel = () => {
        axios.get('/gameType/getAll')
            .then((response) => {
                setGameType(response.data)
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }

    useEffect(() => {
        getAllGameTypePanel()
    }, [])

    return (
        <div className='sm:grid md:grid lg:grid grid gap-4 lg:grid-cols-7 sm:grid-cols-3 md:grid-cols-4  grid-cols-3 mt-10'>
            {gameType.length != 0 ?
                gameType.map((key, index) => (
                    <MiniGameCard key={index} id={key.id} gameTypeName={key.gameTypeName}></MiniGameCard>
                ))
                :
                Array.from({ length: 7 }, (_, i) => (
                    <LoadingMiniCard key={i}></LoadingMiniCard>
                ))
            }
        </div>
    )
}

export default MiniGamePanel