import React from 'react'
import { formatDate } from '../../../service/FormatDate';


function RankedGameMiniCard(props) {
    const { id, gameName, gameCreatorUserId, gameCreatorUserName, gameTypeName, gamePreviewImage, playerCount, gameCreatorUserAvatarName, createDate } = props;

    return (
        <div  className="block hover:scale-95 duration-200  cursor-pointer  ">
            <img
                alt="Guess The Game Image"
                src={`data:image/jpeg;base64,${gamePreviewImage}`}
                className=" w-full object-fill h-32 sm:h-32 lg:h-40 rounded-t-md"
            />
            <div className=' bg-zinc-800 rounded-b-md'>
                <div className="py-2 text-center ">
                    <a href="#" className="block lg:text-lg sm:text-sm text-sm   text-white" tabIndex="0" role="link">{gameName}</a>
                </div>

                <div className=' flex justify-end '>
                    <div href="#" className=" text-center lg:text-md  sm:text-sm text-xs   text-gray-500 mb-2 mx-2" tabIndex="0" role="link">
                        {formatDate(createDate)}
                    </div>
                </div>

            </div>
        </div >
    )
}

export default RankedGameMiniCard