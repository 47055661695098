import React from 'react'

function LoadingTodayGame() {
  return (
    <div className="block shadow-lg  hover:scale-105  cursor-pointer  duration-200 rounded-lg">
    <div className='w-full lg:h-96 md:h-60 sm:h-40 h-32 rounded-t-md animate-pulse bg-zinc-700'></div>
    <div className=' bg-zinc-800 rounded-b-md'>
        <div className="py-4 flex items-center justify-center">
            <div className="h-4 w-1/3 mb-2 bg-zinc-700 rounded animate-pulse justify-center"></div>
        </div>

    </div>
</div>
)
}

export default LoadingTodayGame