import React from 'react'
import { FaCircleInfo, FaImage } from "react-icons/fa6";
import inform from '../../../assets/inform.png'
import { useTranslation } from 'react-i18next';

function ViewGameInfo(props) {
    const [showModal, setShowModal] = React.useState(false);
    const { t } = useTranslation();

    return (
        <>
            <a

                onClick={() => setShowModal(true)}
                type="button">
                <FaCircleInfo className='lg:text-xl sm:text-sm cursor-pointer text-white items-center'></FaCircleInfo>
            </a>
            {showModal ? (
                <>
                    <div className="justify-center sm:px-20 text-white px-2  items-center font-bodyFont  mt-10 overflow-x-hidden overflow-y-auto fixed inset-0 z-50  outline-none focus:outline-none backdrop-blur-sm">

                        <div
                            className=" font-bodyFont w-auto my-6 mx-auto max-w-3xl">

                            <div className=" bg-zinc-800  border-gray-600  rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
                                {/*body*/}
                                {inform === null ?
                                    <div className='text-white border-zinc-600 rounded-lg'>
                                        <div className="flex  h-screen justify-center items-center prounded-lg  ">
                                            <div className="">
                                                <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-2 h-14 w-14">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <img
                                        alt="Guess The Game Image"
                                        src={inform}
                                        className="w-full p-4"
                                    />
                                }

                                <div className='text-left mx-5'>
                                    <div className=''>
                                        <p htmlFor="HeadlineAct" className="block lg:text-lg sm:text-sm text-white mt-4">
                                            {t('gameInfo.text2')}
                                        </p>
                                        <p htmlFor="HeadlineAct" className="block lg:text-lg sm:text-sm   text-white mt-2">
                                            {t('gameInfo.text1')}
                                        </p>

                                        <p htmlFor="HeadlineAct" className="block lg:text-lg sm:text-sm  text-white mt-2">
                                            {t('gameInfo.text3')}
                                        </p>

                                        <p htmlFor="HeadlineAct" className="block lg:text-lg sm:text-sm  text-white mt-2">
                                            {t('gameInfo.text4')}
                                        </p>
                                    </div>
                                </div>
                                <div className=" relative block items-center align-middle ml-4  ">
                                    <div className="flex items-center justify-end mb-4  w-full rounded-b">
                                        <button
                                            className="border-white border-2 duration-300 mt-2 transform inline-block rounded-md text-base bg-zinc-800 px-3 py-2  mr-2 ml-2 hover:text-blue-500 hover:border-blue-500 text-white  hover:bg-zinc-900 "
                                            type="button"
                                            onClick={() => setShowModal(false)}
                                        >
                                            {t('gameInfo.buttonText1')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null
            }
        </>
    )
}

export default ViewGameInfo