import React, { useState } from 'react'
import axios from 'axios';
import { FaAt } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next';
function ForgotPassword() {
    const { t } = useTranslation();

    const [error, setError] = useState(null);
    const [userMail, setUserMail] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const closeAlert = () => {
        setError(null);
    };

    const validateFormInput = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post('/user/resetPassword/send?userMail=' + userMail)
            .then((response) => {
                setIsLoading(false);
                setIsSent(true);
                setTimeout(() => {
                    setIsSent(false);
                }, 2000);
            })
            .catch((error) => {
                setError(t(error.response.data));
                setIsLoading(false);
                setIsSent(false);
            });
    }

    return (
        <div>
            {isLoading ?
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-opacity-0 backdrop-filter backdrop-blur-sm z-10">
                    <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-4 h-40 w-40 sm:h-20 sm:w-20 mb-12"></div>
                    <label className="block  text-2xl text-white my-2 text-left">
                        {t("forgotPasswordPanel.text2")}
                    </label>
                </div>
                :
                null
            }
            {isSent ?
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-opacity-0 backdrop-filter backdrop-blur-sm z-10">
                    <label className="block  text-2xl text-white my-2 text-left">
                        {t("forgotPasswordPanel.text3")}
                    </label>
                </div>
                :
                null
            }
            {error && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-black opacity-40 z-40"></div>
                    <div
                        role="alert"
                        className="rounded border-s-4 border-red-500 bg-zinc-900 p-4 relative z-50 max-w-xl mx-auto w-full"
                    >
                        <button
                            onClick={closeAlert}
                            className="absolute top-2 right-2 text-white focus:outline-none"
                        >
                            <span className="sr-only">Kapat</span>
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <strong className="block text-6xl mb-5 font-medium text-white">
                            :(
                        </strong>
                        <p className="mt-2 text-xl text-white">
                            {error}
                        </p>
                    </div>
                </div>
            )}
            <div className="mx-auto min-h-screen px-4 py-16 sm:px-6 lg:px-8 font-bodyFont bg-zinc-90  ">
                <div className='mx-auto max-w-lg mt-20 '>
                    <h1 className="text-left lg:text-6xl font-bold text-white text-5xl">
                        {t("forgotPasswordPanel.title1")}
                    </h1>
                </div>
                <div className="mx-auto mt-5 max-w-lg border base-border border-gray-500 rounded-md sm:p-6 lg:p-8  ">
                    <form
                        onSubmit={validateFormInput}
                        action=""
                        className="mt-6 space-y-4 rounded-lg  "
                    >
                        <div>
                            <label for="email" className="sr-only">Email</label>

                            <div className="relative ">
                                <input
                                    onChange={(e) => setUserMail(e.target.value)}
                                    required
                                    type="email"
                                    id="userMail"
                                    maxLength={30}
                                    name="userMail"

                                    className="w-full rounded-lg  text-white border-gray-700 border-2 p-4 pe-12 text-lg shadow-sm  bg-zinc-900"
                                    placeholder={t("forgotPasswordPanel.text1")}

                                />

                                <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <FaAt className='text-gray-400'></FaAt>

                                </span>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="block w-full  rounded-lg bg-red-500 hover:bg-red-600 duration-200 px-5 py-3 text-xl font-medium text-white"
                        >
                            {t("forgotPasswordPanel.buttonText1")}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword