import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { FaCircleArrowDown } from 'react-icons/fa6'
import axios from 'axios';
import RankedMiniGameCard from '../../home/card/RankedMiniGameCard';
import NormalMiniGameCard from '../../home/card/NormalMiniGameCard';
import TrendGameCard from '../../home/card/TrendGameCard';
import { useTranslation } from 'react-i18next';
import LoadingMiniGameCard from '../../home/card/LoadingMiniGameCard';
import LoadingTrendGameCard from '../../home/card/LoadingTrendGameCard';
import { Helmet } from 'react-helmet';

function GameCategory() {
    const { gameCategoryId } = useParams();
    const { t } = useTranslation();
    const [adminGames, setAdminGames] = useState()
    const [pouplarGames, setPopularGames] = useState()
    const [gameTypeCount, setGameTypeCount] = useState()
    const [gameTypeName, setGameTypeName] = useState()


    const [pageableCategoryGames, setPageableCategoryGames] = useState([])
    const [currentPage, setCurrentPage] = useState(0);


    const [image, setImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [arrowControler, setArrowControler] = useState(true)



    const fillAdminGames = () => {
        axios.get('/game/adminGamesWithLimit/' + gameCategoryId + "?limit=2")
            .then((response) => {
                setAdminGames(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fillPopularGames = () => {
        axios.get('/game/topTrendGames/gameCategory/' + gameCategoryId + "?limit=3")
            .then((response) => {
                setPopularGames(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fillGameTypeName = () => {
        axios.get('/gameType/' + gameCategoryId)
            .then((response) => {
                setGameTypeName(response.data)

                import(`../../../assets/${gameCategoryId}.gif`)
                    .then((res) => {
                        setImage(res.default);
                    })
                    .catch((hata) => {
                        console.error('Dosya içe aktarımı sırasında bir hata oluştu: ', hata);
                    });

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getAllGameTypeGameCount = () => {
        axios
            .get("/game/getAllGameTypeGameCount/" + gameCategoryId)
            .then(function (response) {
                return response.data;
            })
            .then(
                (result) => {
                    setGameTypeCount(result);
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    const getAllGameTypeGameCountPageable = async (requestedPage) => {
        console.log("requestedPage: " + requestedPage);
        setIsLoading(true);
        try {
            const response = await axios.get(`/game/getAllGameTypeGamePageable/${gameCategoryId}?page=${requestedPage}&size=3`);
            setPageableCategoryGames((prevData) => {
                const newData = {
                    ...response.data,
                    content: [...(prevData.content || []), ...(response.data.content || [])],
                };
                return newData;
            });
            setIsLoading(false);
            if (response.data.totalPages - 1 === requestedPage) {
                setArrowControler(false);
            } else {
                setArrowControler(true);
            }
        } catch (error) {
            console.log(error);
        }
    }


    const handleNextPageClick = () => {
        if (currentPage < pageableCategoryGames.totalPages - 1) {
            setCurrentPage(currentPage + 1);
            getAllGameTypeGameCountPageable(currentPage + 1); // Update here
        }
    };


    useEffect(() => {
        fillAdminGames()
        fillPopularGames()
        getAllGameTypeGameCountPageable(currentPage);
        fillGameTypeName()
        getAllGameTypeGameCount()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0); // Sayfanın en üstüne scroll yap
    }, []);
    
    return (

        <div className="mx-auto max-w-6xl lg:px-10  md:px-10 px-5   font-bodyFont">
             <Helmet>
                <title>Guess The Game - Game Guess - Game Mixed, Characters, Map, Npc Guess Game and More </title>
                <meta name="description" content="Game Guess - Guess the Game. Guess the game, enjoy the gaming experience, and compete to be on the top player list! Find exclusive and exciting game guess in this category. Guess the game from the pictures! The best game characters and game picture guess games are here!" />
            </Helmet>
            <div className="mx-auto max-w-7xl relative">
                {image === null ?
                    <div className='mt-10 flex  justify-center items-center'>
                        <div className=" border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-4 lg:h-44 lg:w-44 ">
                        </div>
                    </div>
                    :
                    <div className="mt-10 flex  justify-center items-center">
                        <div
                            className="inline-block lg:h-52 lg:w-52  sm:h-24 sm:w-24 h-24 w-24 rounded-full ring-4 ring-zinc-500"
                            style={{
                                backgroundImage: `url(${image})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                            }}
                        />
                    </div>
                }
                <p className='flex justify-center text-white  lg:text-3xl  sm:text-lg font-bold  mt-2'>
                    {gameTypeName ? t('mainPage.gamePanel.' + gameTypeName.gameTypeName) : null}
                </p>
                <div className='mt-0 '>
                    <p className="flex justify-end text-white lg:text-xl sm:text-sm ">
                        {t('gameCategoryPage.text1')} {gameTypeCount}
                    </p>
                </div>
                <hr className="   border-gray-700 mt-5 " />
                <div className='flex justify-between mt-20'>
                    <h1 className="lg:text-3xl sm:text-xl font-bold  text-white ">
                        {t('gameCategoryPage.title1')}
                    </h1>
                    <Link
                        onClick={() => {
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                            });
                        }}
                        to={"/rankedGames"} >
                        <h1 className="lg:text-xl sm:text-base font-bold   text-red-500  hover:animate-pulse  hover:scale-105">
                            {t('mainPage.gamePanel.text1')}
                        </h1>
                    </Link>
                </div>
                <div className='mx-1 mt-6'>
                    <div className='grid  gap-4 lg:grid-cols-2 sm:grid-cols-1 '>
                        {
                            adminGames ?
                                adminGames.map((key, index) => (
                                    <Link to={"/gameView/" + key.id} key={index}>
                                        <RankedMiniGameCard id={key.id} gameName={key.gameName} gameCreatorUserId={key.gameCreatorUserId} gameCreatorUserName={key.gameCreatorUserName} gameTypeName={key.gameTypeName} gamePreviewImage={key.gamePreviewImage} playerCount={key.playerCount} gameCreatorUserAvatarName={key.gameCreatorUserAvatarName} admin={key.admin}></RankedMiniGameCard>
                                    </Link>
                                ))
                                :
                                <>

                                    <LoadingMiniGameCard></LoadingMiniGameCard>
                                    <LoadingMiniGameCard></LoadingMiniGameCard>

                                </>
                        }
                    </div>
                    <div className='flex justify-between mt-10 '>
                        <h1 className="lg:text-3xl sm:text-xl font-bold  text-white my-5">
                            {t('gameCategoryPage.title2')}
                        </h1>
                    </div>
                    <div className='grid gap-4 lg:grid-cols-3 sm:grid-cols-1 '>
                        {
                            pouplarGames ?
                                pouplarGames.map((key, index) => (
                                    <Link to={"/gameView/" + key.id} key={index}>

                                        <TrendGameCard id={key.id} gameName={key.gameName} gameCreatorUserId={key.gameCreatorUserId} gameCreatorUserName={key.gameCreatorUserName} gameTypeName={key.gameTypeName} gamePreviewImage={key.gamePreviewImage} playerCount={key.playerCount} gameCreatorUserAvatarName={key.gameCreatorUserAvatarName} isVerified={key.verified} ></TrendGameCard>
                                    </Link>
                                ))
                                :
                                <>
                                    <LoadingTrendGameCard></LoadingTrendGameCard>
                                    <LoadingTrendGameCard></LoadingTrendGameCard>
                                    <LoadingTrendGameCard></LoadingTrendGameCard>
                                </>
                        }
                    </div>

                    <div className='flex justify-between mt-10 '>
                        <h1 className="lg:text-3xl sm:text-xl font-bold  text-white my-5">
                            {t('gameCategoryPage.title3')}
                        </h1>
                    </div>
                    <div className='grid gap-4 lg:grid-cols-3 sm:grid-cols-1 '>

                        {
                            pageableCategoryGames && pageableCategoryGames.content ?
                                pageableCategoryGames.content.map((key, index) => (
                                    <Link to={"/gameView/" + key.id} key={index}>
                                        <NormalMiniGameCard id={key.id} gameName={key.gameName} gameCreatorUserId={key.gameCreatorUserId} gameCreatorUserName={key.gameCreatorUserName} gameTypeName={key.gameTypeName} gamePreviewImage={key.gamePreviewImage} playerCount={key.playerCount} gameCreatorUserAvatarName={key.gameCreatorUserAvatarName} isVerified={key.verified} admin={key.admin}></NormalMiniGameCard>
                                    </Link>
                                ))
                                :
                                <>

                                    <LoadingMiniGameCard></LoadingMiniGameCard>
                                    <LoadingMiniGameCard></LoadingMiniGameCard>
                                    <LoadingMiniGameCard></LoadingMiniGameCard>

                                </>
                        }
                    </div>
                </div>
            </div>
            {
                !arrowControler ?
                    null :
                    isLoading ? (
                        <div className='flex justify-center mt-10'>
                            <div className="border-t-transparent border-solid animate-spin rounded-full border-red-500 border-8 h-20 w-20"></div>
                        </div>
                    ) : (
                        <div className='flex justify-center items-center mt-10 '>
                            <FaCircleArrowDown
                                className='animate-bounce lg:h-14 lg:w-14 h-10 w-10 text-red-500 cursor-pointer'
                                onClick={handleNextPageClick}
                            ></FaCircleArrowDown>
                        </div>
                    )
            }
        </div >
    )
}

export default GameCategory