import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaCircleInfo, FaXmark } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useToken } from '../../service/context/TokenProvider';
import { formatDate } from '../../service/FormatDate';

function AdminBannedAccount() {
    const [pageableBanned, setPageableBanned] = useState([])
    const { tokenData } = useToken();
    const { accessToken } = tokenData
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [dBanned, setDBanned] = useState(false);
    const navigate = useNavigate()
    const [checkKey, setCheckKey] = useState(false)

    const getAllBannedAccout = async (page) => {
        try {
            const response = await axios.get(`/bannedAccount/getAllBannedAccountPageable?page=${page}&size=25`, {
                headers: {
                    Authorization: accessToken
                }
            });
            setPageableBanned(response.data);
            setIsLoaded(true);
        } catch (error) {
            setIsLoaded(true);
            setError(error);
        }
    }

    const deleteBannedAccount = async (id) => {
        await axios.delete(`/bannedAccount/${id}`, {
            headers: {
                Authorization: accessToken
            }
        })
            .catch(function (error) {
                setDBanned(error)
            });
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.post("/param/getPanelLink/send?paramValue=" + localStorage.getItem('key'), {}, {
                    headers: {
                        Authorization: accessToken,
                    },
                });
                if (response.status === 200) {
                    setCheckKey(true);
                    getAllBannedAccout(currentPage); // Sayfa yüklendiğinde ilk çağrıyı yap
                } else if (response.status === 404) {
                    localStorage.removeItem('key');
                    navigate("/forbidden");
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setCheckKey(false);
                    localStorage.removeItem('key');
                    navigate("/forbidden");
                }
                console.error("Bir hata oluştu: ", error);
            }
        }
        fetchData();
    }, [localStorage.getItem('key')]);

    useEffect(() => {
        getAllBannedAccout(currentPage); // Sayfa yüklendiğinde ilk çağrıyı yap
    }, [currentPage])






    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber); // Sayfa numarasını güncelle
    };
    const handlePrevPageClick = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPageClick = () => {
        if (currentPage < pageableBanned.totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };
    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen flex-col">
                <div className="text-4xl font-bold text-red-500 mb-4">HATA!</div>
                <div className="text-2xl font-bold text-red-500">HATA!</div>
            </div>
        )

    } else if (!checkKey) {
        return (
            <div className='fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black  z-50'>
                <div className='text-white border-zinc-600 rounded-lg'>
                    <div className='flex justify-center items-center mt-20'>
                        <div className='border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-8 h-28 w-28'></div>
                    </div>
                </div>
            </div>
        );
    }
    else if (!isLoaded) {

        return (
            <div className='text-white border-zinc-600 rounded-lg'>
                <div className="flex  justify-center items-center mt-20 prounded-lg  ">
                    <div className="">
                        <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-8 h-28 w-28">
                        </div>
                    </div>
                </div>
            </div>
        )

    } else {
        return (
            <div className="flex flex-col font-bodyFont text-white">
                <div className=" mx-auto sm:mx-40">
                    <h2 className="text-2xl font-semibold my-2 ml-2"> Şikayetler</h2>



                </div>
                <div className="overflow-x-auto  ">
                    <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden">
                            <div className="inline-block min-w-full overflow-x-auto mt-10">

                                {pageableBanned ? (
                                    pageableBanned.length === 0 ?
                                        (
                                            <p className="text-xl  mt-1 text-start align-middle ">Banlanan hesap bulunamadi.</p>

                                        ) : (
                                            <table className="min-w-full text-left text-sm font-light">
                                                <thead className="border-b font-medium border-neutral-500">
                                                    <tr>
                                                        <th scope="col" className="px-4 py-2 text-blue-500 text-lg">*</th>
                                                        <th scope="col" className="px-4 py-2 text-blue-500 text-lg">id</th>
                                                        <th scope="col" className="px-4 py-2 text-blue-500 text-lg">UserMail</th>
                                                        <th scope="col" className="px-4 py-2 text-blue-500 text-lg">Banned Date</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pageableBanned ? pageableBanned.content.map((key, index) => (
                                                        <tr
                                                            className="border-b transition duration-300 ease-in-out  border-neutral-500 hover:bg-neutral-600">
                                                            <td className="whitespace-nowrap px-4 py-1 font-medium">{index + 1} </td>
                                                            <td className="whitespace-nowrap px-4 py-1 font-medium">{key.id} </td>
                                                            <td className="whitespace-nowrap px-4 py-1">{key.userMail} </td>

                                                            <td className="whitespace-nowrap px-4 py-1"> {formatDate(key.bannedDate)}  </td>

                                                            <td className="whitespace-nowrap px-4 py-1">
                                                                <span className="flex justify-between">
                                                                    <FaXmark onClick={() => deleteBannedAccount(key.id)} className='text-red-400 text-lg cursor-pointer hover:text-red-500 duration-200'></FaXmark>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )) : null}


                                                </tbody>
                                            </table>
                                        )

                                ) : (
                                    null
                                )}


                            </div>
                            <div className="flex justify-center">
                                <a
                                    href="#"
                                    onClick={handlePrevPageClick}
                                    className="flex items-center justify-center px-2 py-2 mx-1 transition-colors duration-300 transform bg-gray-800 text-white rounded-md rtl:-scale-x-100  "
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </a>

                                {Array.from({ length: pageableBanned.totalPages }, (_, i) => (
                                    <a
                                        onClick={() => handlePageClick(i)}
                                        className={`px-2 py-2 mx-1 cursor-pointer transition-colors duration-300 transform rounded-md sm:inline text-gray-200  ${currentPage === i ? 'font-bold bg-red-500 text-white' : ''}`}
                                    >
                                        {i + 1}
                                    </a>
                                ))}

                                <a
                                    href="#"
                                    onClick={handleNextPageClick}
                                    className="flex items-center justify-center px-2 py-2 mx-1 transition-colors duration-300 transform bg-gray-800 text-white rounded-md rtl:-scale-x-100  "
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>)

    }

}

export default AdminBannedAccount