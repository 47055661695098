import React from 'react'

function UserLoadingGameCard() {
    return (
        <div href="#" className="block hover:scale-105 duration-200 cursor-pointer animate-pulse ">
            <div className='h-32 w-full sm:h-40 lg:h-40 rounded-t-md bg-zinc-700 animate-pulse'></div>
            <div className="flex  items-center justify-center bg-zinc-800 rounded-b-md">
                <div className="h-4 py-3 w-60 my-4  bg-zinc-700  rounded-lg animate-pulse "></div>
            </div>
        </div>
    )
}

export default UserLoadingGameCard