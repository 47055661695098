import { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { FaAngleDown } from "react-icons/fa6";
import { useTranslation } from "react-i18next"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Translate() {
    const languageData = [
        { name: 'en' },
        { name: 'tr' },
    ]

    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const normalizeLanguageCode = (langCode) => {
        return langCode.split('-')[0]; // Dil kodunu "-" karakterine göre böler ve ilk parçayı alır (örneğin, tr-TR'den tr yapar)
    };

    const clickHandle = async (lang) => {
        const normalizedLang = normalizeLanguageCode(lang);
        await i18n.changeLanguage(normalizedLang);
        setSelectedLanguage(normalizedLang);
    }
    useEffect(() => {
        const normalizedUserLanguage = normalizeLanguageCode(i18n.language);
        const isUserLanguageSupported = languageData.some(item => item.name.toLowerCase() === normalizedUserLanguage);

        if (!isUserLanguageSupported || normalizedUserLanguage !== 'tr') {
            i18n.changeLanguage('en');
            setSelectedLanguage('en');
        } else {
            setSelectedLanguage(normalizedUserLanguage);
        }
    }, [])


    return (
        <Menu as="div" className="relative inline-block text-left font-bodyFont">
            <div className="">
                <Menu.Button className="inline-flex text-lg items-center gap-x-1.5 bg-transparent px-3 py-2 text-white hover:text-red-500 duration-200 ">
                    {selectedLanguage}
                    <FaAngleDown className="mt-1" aria-hidden="true"></FaAngleDown>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 w-32 origin-top-right divide-y divide-gray-100 rounded-md bg-zinc-900 shadow-md shadow-zinc-800 ring-1 ring-zinc-600">
                    <div className="py-1">
                        {languageData.map((item) => (
                            <Menu.Item key={item.name}>
                                <button
                                    onClick={() => clickHandle(item.name)}
                                    className={classNames(
                                        'text-white w-full items-start justify-start flex',
                                        'block px-4 py-2 text-sm hover:bg-zinc-800'
                                    )}
                                >
                                    {item.name}
                                </button>

                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

